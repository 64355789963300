import generatedGitInfo from "../../generatedGitInfo.json";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  Alert,
  Box,
  Button,
  FormControl,
  Icon,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import background from "../../assets/headerHome.jpeg";

import logo from "../../assets/logoRoxa.svg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ReCAPTCHA from "react-google-recaptcha";

export default function Login(params) {
  const recaptchaRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [Null, setNull] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertServiceOpen, setAlertServiceOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [captcha, setCaptcha] = useState(false);
  const [openRecaptcha, setOpenRecaptcha] = useState(false);

  const url = `${window?.config?.REACT_APP_BASE_URL || ""}`;
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!login || !senha) {
      setNull(true);
      return;
    }

    setLoading(true);
    try {
      setCaptcha(false);
      const response = await axios.post(
        `${url}/login`,
        { login, senha },
        { withCredentials: false }
      );

      if (response?.data?.token) {
        const { perfil, logs, ...userData } = response.data;
        localStorage.setItem("1", JSON.stringify(userData));

        localStorage.setItem("2", JSON.stringify(perfil));

        localStorage.setItem("logs", JSON.stringify(logs));

        if (
          response.data.perfilAlreadyExists === true &&
          response.data.perfil.status === true
        ) {
          navigate("/noc");
        } else {
          navigate("/primeiroAcesso");
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `${
          error?.response?.data?.error ||
          "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
      if (error.response.status === 403) {
        setOpenRecaptcha(true);
      }
      recaptchaRef.current.reset();
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const styles = {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    paperImg: {
      backgroundImage: `url(${background})`,
      backgroundSize: "auto 100%",
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      borderRadius: "0px!important",
      width: "60vw",
      height: "100vh",
      minWidth: "300px",
      minHeight: "500px",
      display: { xs: "none", md: "flex" },
      justifyContent: "center",
      alignContent: "center",
    },
    boxImg: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignContent: "center",
      justifyContent: " center",
      alignItems: "center",
      flexDirection: "column",
      color: "white",
      padding: "15px",
    },
    textTitle: {
      fontSize: "3em",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "1em",
      width: "60%",
    },
    textSubTitle: { fontSize: "1.25em", width: "60%", fontWeight: "300" },
    paperForm: {
      minHeight: "500px",
      minWidth: "310px",
      padding: "40px 80px",
      width: { xs: "100vw", md: "40vw" },
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      display: "flex",
    },
    iconLogo: { height: "auto", width: "auto" },
    imgLogo: { height: "auto", width: "270px" },
    boxForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "20px",
      width: { xs: "90vw", md: "100%" },
    },
    formControl: { m: 1, width: "90%", marginTop: "10px" },
    buttonIn: {
      width: "90%",
      borderRadius: "20px",
      marginTop: "10px",
      height: "40px",
    },
    paperContact: {
      bottom: "0px",
      position: "absolute",
      width: { xs: "100vw", md: "40vw" },
      textAlign: "center",
      padding: "0px 20px",
    },
  };

  return (
    <>
      <Box sx={styles.container}>
        <Paper sx={styles.paperImg}>
          <Box sx={styles.boxImg}>
            <Typography sx={styles.textTitle}>
              Monitoramento eficiente e confiável.
            </Typography>
            <br></br>
            <Typography sx={styles.textSubTitle}>
              Monitoramento de infraestrutura de redes para provedores de
              internet, com suporte confiável e atencioso.
            </Typography>
          </Box>
        </Paper>

        <Paper sx={styles.paperForm}>
          <Stack
            direction={"column"}
            gap={"15px"}
            alignItems={"center"}
            sx={{ padding: "0px", width: "100%" }}
          >
            <img src="" />
            <Icon style={styles.iconLogo}>
              <img src={logo} style={styles.imgLogo} alt="logo mw" />
            </Icon>

            <Box sx={styles.boxForm}>
              <FormControl sx={styles.formControl} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-login">
                  Login
                </InputLabel>
                <OutlinedInput
                  error={!login && Null}
                  id="outlined-adornment-login"
                  type="text"
                  onKeyDown={handleKeyDown}
                  onChange={(event) => {
                    setLogin(event.target.value);
                  }}
                  label="Login"
                />
                {!login && Null && (
                  <FormHelperText error id="accountId-error">
                    Login Inválido
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                sx={{ m: 1, width: "90%", marginTop: "10px" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  error={!senha && Null}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onKeyDown={handleKeyDown}
                  onChange={(event) => {
                    setSenha(event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {!senha && Null && (
                  <FormHelperText error id="accountId-error">
                    Senha Inválida
                  </FormHelperText>
                )}
              </FormControl>

              {openRecaptcha && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  style={{ margin: "10px" }}
                  sitekey={window?.config?.REACT_APP_SITE_KEY || ""}
                  onChange={setCaptcha}
                />
              )}
              <Button
                variant="contained"
                disabled={openRecaptcha ? !captcha : loading}
                onClick={() => {
                  handleLogin();
                }}
                sx={styles.buttonIn}
              >
                Entrar
              </Button>

              <br></br>
              <Typography sx={{ fontSize: "0.8em" }}>
                Ainda não tem uma conta?{" "}
              </Typography>
              <Link sx={{ textDecoration: "none", fontSize: "0.8em" }}>
                {" "}
                Solicite Agora!
              </Link>
              <Typography sx={{ fontSize: "0.8em" }}>
                Não está conseguindo acessar?{" "}
              </Typography>
              <Link
                href="/resetPassword"
                sx={{ textDecoration: "none", fontSize: "0.8em" }}
              >
                {" "}
                Redefinir senha
              </Link>
              <Typography sx={{ fontSize: "0.8em", mt: "10px" }}>
                Version: 0.0.1({generatedGitInfo.gitCommitHash})
              </Typography>
            </Box>
            <Paper sx={styles.paperContact}>
              <hr />
              <Typography sx={{ fontSize: "0.9em" }}>
                Fale conosco nas redes sociais{" "}
              </Typography>
              <Button href="https://www.facebook.com/mwsolucoesmonitoramento/">
                <FacebookIcon />
              </Button>
              <Button href="https://www.linkedin.com/company/mw-solucoes/">
                <LinkedInIcon />
              </Button>
              <Button href="https://instagram.com/mwsolucoesltda?igshid=MzRlODBiNWFlZA==">
                <InstagramIcon />
              </Button>
            </Paper>
          </Stack>
        </Paper>
      </Box>
      <Snackbar
        autoHideDuration={6000}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Erro: senha ou login incorreto</Alert>
      </Snackbar>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
