import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { useState } from "react";
import api from "../../../../../services/api";

const styles = {
  BoxCampo: {
    display: "flex",
    flexDirection: "column",
    alingItens: "flex-start",
    gap: "4px",
    width: "300px",
    height: "48px",
  },

  TitleCampo: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "primary.main",
    display: "flex",
    alingItens: "center",
  },
  TextCampo: {
    fontSize: "16px",
    lineHeight: "22px",
    cursor: "pointer",
  },
  TextField: {
    width: "200px",
  },
  DialogTitle: { color: "primary" },
  checkboxGroup: {
    display: "flex",
    width: "200px",
  },
};

export default function ModalNovoCliente(props) {
  const { setOpenModal, openModal, setClientes, tabValue } = props;
  const [snackbar, setSnackbar] = useState(null);
  const [confirmarLoading, setConfirmarLoading] = useState(false);
  const [empresa, setEmpresa] = useState("");
  const [numero, setNumero] = useState(0);
  const [produtoCreate, setprodutoCreate] = useState({
    easyflow: false,
    easymon: false,
  });

  function clearModal() {
    setprodutoCreate({ ...produtoCreate, easyflow: false, easymon: false });
    setEmpresa("");
    setNumero(0);
  }

  const handleProdutoCreate = (event) => {
    setprodutoCreate({
      ...produtoCreate,
      [event.target.name]: event.target.checked,
    });
  };

  async function handleApi() {
    const response = await api.get("/acessos");

    setClientes(response.data);
  }

  async function novoCliente(params) {
    let produtosIniciais = [];
    if (produtoCreate.easymon === true) {
      produtosIniciais.push("easymon");
    }
    if (produtoCreate.easyflow === true) {
      produtosIniciais.push("easyflow");
    }
    try {
      setConfirmarLoading(true);
      const response = await api.post("/novoCliente", {
        empresa,
        numero,
        parceiro: tabValue,
        produtosIniciais,
        usuarioId: JSON.parse(localStorage.getItem("1")).refreshToken.usuarioId,
      });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados: Número já existente",
          severity: "error",
        });
        setConfirmarLoading(false);
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });

        handleApi();
        setEmpresa("");
        setNumero(0);
        setConfirmarLoading(false);
        clearModal();

        setOpenModal(false);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setConfirmarLoading(false);
      console.error(error);
    }
  }

  async function handleApi() {
    const response = await api.get("/acessos");
    setClientes(response.data);
  }

  const { easyflow, easymon } = produtoCreate;
  return (
    <Dialog
      id="dialog-create-modal"
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      sx={styles.modal}
    >
      <DialogTitle color={"primary"}>{"Criar novo cliente."}</DialogTitle>
      <DialogContent>
        <Stack
          direction={"column"}
          spacing={2}
          alignItems="flex-start"
          justifyContent="center"
        >
          <TextField
            variant="standard"
            label="Nome do cliente"
            value={empresa}
            onChange={(event) => {
              setEmpresa(event.target.value);
            }}
            sx={styles.TextField}
          />
          <TextField
            variant="standard"
            label="Número"
            type={"number"}
            sx={styles.TextField}
            value={numero}
            onChange={(event) => {
              setNumero(event.target.value);
            }}
          />
          <Box sx={styles.checkboxGroup}>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Escolha os produtos</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={easymon}
                      onChange={handleProdutoCreate}
                      name="easymon"
                    />
                  }
                  label="EasyMon"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={easyflow}
                      onChange={handleProdutoCreate}
                      name="easyflow"
                    />
                  }
                  label="EasyFlow"
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color={"secondary"}
          onClick={() => {
            clearModal();
            setOpenModal(false);
          }}
        >
          Cancelar
        </Button>
        <Button onClick={novoCliente} disabled={confirmarLoading}>
          Confirmar
        </Button>
      </DialogActions>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Dialog>
  );
}
