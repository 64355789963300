import * as React from "react";
import Box from "@mui/material/Box";

import BarChartIcon from "@mui/icons-material/BarChart";
import { createSvgIcon, Typography } from "@mui/material";
import { ReactComponent as Grafana } from "../../assets/nocImg/grafana.svg";
import { ReactComponent as Zabbix } from "../../assets/nocImg/zabbix.svg";
import { ReactComponent as EasyFlow } from "../../assets/nocImg/IconEasyFlow.svg";

import {
  Engineering,
  GroupsOutlined,
  HomeOutlined,
  ManageAccounts,
  PermMediaOutlined,
} from "@mui/icons-material";

import Rodape from "../rodape";
import { Outlet, useOutletContext } from "react-router-dom";
import { useTheme } from "@emotion/react";
import RightBar from "../../componentes/rightBar";

export default function NocPage() {
  const [openRightBar, setOpenRightBar, openDrawer, setOpenDrawer] =
    useOutletContext();
  const theme = useTheme();
  const [titlePage, setTitlePage] = React.useState("");
  const colorMode = theme.palette.mode;

  const styles = {
    container: { display: "flex" },
    paper: { height: "100vh", position: "sticky", top: 0, zIndex: 1 },
    tab: {
      minWidth: "60px",
      minHeight: "48px",
      maxWidth: "155px",
      justifyContent: "start",
    },
    icons: {
      color:
        colorMode === "light"
          ? "rgba(0, 0, 0, 0.6)"
          : "rgba(255, 255, 255, 0.7)",
    },
    containerOutlet: { flexGrow: 1, paddingTop: 5, paddingInline: 3 },
    titlePage: {
      flexGrow: 1,
      fontWeight: 500,
      fontSize: "1.5rem",
      color: "theme.palette.text.primary",
      marginX: 6,
      marginY: 1,
    },
  };

  const GrafanaIcon = createSvgIcon(<Grafana />);

  const ZabbixIcon = createSvgIcon(<Zabbix />);
  const EasyFlowIcon = createSvgIcon(<EasyFlow />);

  const rightBarPages = [
    {
      itemId: "1",
      label: "Home",
      link: "/noc/home",
      icon: <HomeOutlined />,
    },
    {
      itemId: "2",
      label: "Clientes",
      link: "/noc/clientes",
      icon: <GroupsOutlined />,
      nodes: [
        {
          itemId: "2.1",
          label: "Acessos",
          link: "/noc/clientes/acessos",
        },
        {
          itemId: "2.2",
          label: "Relatórios",
          link: "/noc/clientes/relatorios",
        },
        {
          itemId: "2.3",
          label: "Suporte",
          link: "/noc/clientes/suporte",
        },
        {
          itemId: "2.4",
          label: "Dashboard",
          link: "/noc/clientes/dashboard",
        },
        {
          itemId: "2.5",
          label: "Área do Cliente",
          link: "/noc/clientes/areaDoCliente",
        },
        {
          itemId: "2.6",
          label: "EasyReport",
          link: "/noc/clientes/easyReport",
        },
      ],
    },
    {
      itemId: "3",
      label: "Zabbix",
      link: "/noc/zabbix",
      icon: <ZabbixIcon sx={{ fontSize: "22px", ml: "1px" }} />,
      nodes: [
        {
          itemId: "3.1",
          label: "Scanner de Templates",
          link: "/noc/zabbix/verificarTemplates",
        },
        {
          itemId: "3.2",
          label: "Gerenciamento de Tags",
          link: "/noc/zabbix/gerenciamentoDeTags",
        },
        {
          itemId: "3.3",
          label: "Filtro de Hosts",
          link: "/noc/zabbix/filtroDeHosts",
        },
      ],
    },
    {
      itemId: "4",
      label: "Grafana",
      link: "/noc/grafana",
      icon: <GrafanaIcon />,
    },
    {
      itemId: "5",
      label: "Execução",
      link: "/noc/execucao/centralDeInstrucoes",
      icon: <ManageAccounts />,
    },
    {
      itemId: "6",
      label: "Operação",
      link: "/noc/operacao",
      icon: <Engineering />,
      nodes: [
        {
          itemId: "6.1",
          label: "Mídia",
          link: "/noc/operacao/midiaGrafana",
        },
        {
          itemId: "6.2",
          label: "JSON Services",
          link: "/noc/operacao/jsonServices",
        },
        {
          itemId: "6.3",
          label: "Gerar Scripts",
          link: "/noc/operacao/gerarScripts",
        },
      ],
    },
    {
      itemId: "7",
      label: "Setor",
      link: "/noc/acompanhamento",
      icon: <BarChartIcon />,
    },
    {
      itemId: "8",
      label: "EasyFlow",
      link: "/noc/easyFlow",
      icon: <EasyFlowIcon />,
      nodes: [
        {
          itemId: "8.1",
          label: "Relatorios Flow",
          link: "/noc/easyFlow/relatoriosFlow",
        },
      ],
    },
  ];
  return (
    <Box sx={styles.container}>
      <RightBar
        items={rightBarPages}
        title="Noc"
        titlePage={titlePage}
        setTitlePage={setTitlePage}
      />
      <Box component="main" sx={styles.containerOutlet}>
        <Typography variant="h4" sx={styles.titlePage}>
          {titlePage}
        </Typography>
        <Outlet
          context={{ openRightBar, setOpenRightBar, openDrawer, setOpenDrawer }}
        />
        <Rodape />
      </Box>
    </Box>
  );
}
