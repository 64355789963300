import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Modal,
  Paper,
  Stack,
  Button,
  IconButton,
  Typography,
  Divider,
  Avatar,
  Tooltip,
  LinearProgress,
  Chip,
  Grid,
  Menu,
  MenuItem,
  TextField,
  createSvgIcon,
  useTheme,
} from "@mui/material";
import {
  Close,
  Edit,
  Delete,
  MoreVert,
  PushPin,
  AttachFile,
} from "@mui/icons-material";
import Carousel from "react-material-ui-carousel";
import Resizer from "react-image-file-resizer";
import Editor from "../../../../../../componentes/editorChat";
import CardResposta from "../cardResposta";
import DeleteDialog from "../../../../../../componentes/deleteDialog";

import api from "../../../../../../services/api";

const stylesCardComentario = {
  containerChat: {
    width: "700px",
    padding: "20px",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  paperChat: {
    gap: "15px",
    display: "flex",
    flexDirection: "row",
    borderRadius: 3,
    elevation: 0,
    bgcolor: "background.paperChat",
    alignItems: "start",
    padding: "30px 30px 25px 25px",
  },
  fieldComment: {
    width: "640px",
    ml: "100px",
  },
  avatarComment: { width: "50px", height: "50px", marginLeft: "10px" },
  avatarResponse: { width: "30px", height: "30px" },
  buttonShowMore: {
    fontSize: "0.8rem",
    height: "1.6rem",
    width: "9rem",
    margin: "auto",
  },
};
const FileNotFound = createSvgIcon(
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      // fill="#000000"
      stroke="none"
    >
      <path
        d="M2089 4782 c-36 -10 -91 -33 -122 -52 -82 -50 -1233 -1206 -1270
-1276 -59 -112 -57 -64 -57 -1444 0 -1378 -2 -1317 56 -1432 36 -71 123 -159
193 -197 122 -65 25 -61 1671 -61 1646 0 1549 -4 1671 61 70 38 157 126 193
197 59 117 56 2 56 1982 0 1999 4 1868 -61 1991 -38 70 -126 157 -197 193
-114 58 -83 56 -1117 55 -865 0 -956 -2 -1016 -17z m1997 -328 c15 -11 37 -33
48 -48 l21 -27 0 -1819 0 -1819 -21 -27 c-11 -15 -33 -37 -48 -48 -27 -21 -28
-21 -1526 -21 -1498 0 -1499 0 -1526 21 -15 11 -37 33 -48 48 -21 27 -21 33
-24 1257 l-2 1229 428 0 c463 0 488 2 594 56 71 36 159 123 197 193 59 111 61
132 61 603 l0 428 909 -2 c901 -3 910 -3 937 -24z m-2171 -811 c-7 -41 -50
-92 -92 -109 -25 -11 -102 -14 -335 -14 l-303 0 365 365 365 365 3 -287 c1
-159 0 -302 -3 -320z"
      />
      <path
        d="M2215 2945 c-5 -2 -23 -6 -39 -10 -17 -4 -60 -36 -103 -78 l-74 -71
-67 66 c-78 77 -124 96 -188 79 -51 -14 -103 -67 -114 -117 -15 -65 4 -109 78
-186 l66 -69 -66 -67 c-79 -81 -88 -97 -88 -160 0 -87 66 -154 154 -154 58 0
78 12 158 90 l67 66 74 -71 c43 -42 86 -74 103 -78 16 -4 34 -8 41 -10 25 -8
89 18 121 48 18 17 37 49 43 71 17 64 -2 109 -84 193 l-71 74 67 67 c77 78
101 127 92 184 -8 48 -63 113 -103 122 -15 4 -34 9 -42 11 -8 2 -19 2 -25 0z"
      />
      <path
        d="M3285 2938 c-11 -6 -52 -42 -92 -81 l-72 -71 -69 66 c-82 79 -129 98
-194 79 -52 -16 -93 -56 -108 -108 -21 -71 -7 -108 73 -190 l70 -73 -75 -78
c-57 -58 -77 -86 -82 -114 -10 -53 8 -108 46 -145 32 -30 96 -56 121 -48 7 2
25 6 41 10 17 4 60 36 103 78 l74 71 67 -66 c80 -78 100 -90 158 -90 88 0 154
67 154 154 0 63 -9 79 -88 161 l-66 67 71 73 c84 86 93 101 93 164 0 91 -63
153 -155 153 -27 0 -59 -6 -70 -12z"
      />
      <path
        d="M2361 1909 c-248 -26 -583 -136 -644 -212 -57 -69 -44 -168 29 -224
55 -41 104 -41 207 2 188 77 342 112 537 121 229 11 453 -28 662 -115 107 -45
142 -49 195 -25 76 37 109 120 78 199 -21 56 -57 84 -160 125 -292 117 -600
160 -904 129z"
      />
    </g>
  </svg>
);
export default function CardComentario(props) {
  let { chatId } = useParams();
  const {
    comentario,
    usuarioId,
    removerImagem,
    formataComentario,
    fotoUsuario,
    nomeUsuario,
    updateComentario,
    deleteComentario,
    atualizaBadgeTopicos,
    fixarComentario,
    setSnackbar,
    socket,
    CommentIcon,
  } = props;
  const [imagens, setImagens] = useState([]);
  const [respostas, setRespostas] = useState(comentario.respostas);
  const [imagensAgrupadas, setImagensAgrupadas] = useState([]);
  const [imageError, setImageError] = useState(false);
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const [responder, setResponder] = useState(false);
  const [novaResposta, setNovaResposta] = useState(false);
  const [editaComentario, setEditaComentario] = useState("");
  const [editar, setEditar] = useState(false);
  const open = Boolean(anchorEl);
  const [showMoreDesc, setShowMoreDesc] = useState(true);
  const [showButtonMoreDesc, setShowButtonMoreDesc] = useState(false);
  //const [disabledButtonTopic, setDisabledButtonTopic] = useState(true);
  const [disabledButtonEdit, setDisabledButtonEdit] = useState(true);
  const [disableButtonChat, setDisableButtonChat] = useState(true);
  const [openModalFoto, setOpenModalFoto] = useState(false);
  //delete dialog
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [confirmarDelete, setConfirmarDelete] = useState(false);
  const [img, setImg] = useState();
  //socket
  const [getEditComment, setGetEditComment] = useState("");
  const [getDeleteComment, setGetDeleteComment] = useState(false);
  const [getFixComment, setGetFixComment] = useState(false);
  const [getNewResponse, setGetNewResponse] = useState("");
  useEffect(() => {
    setImg(comentario.imagens[0]?.foto);
  }, [comentario]);
  useEffect(() => {
    function chatEvent(data) {
      setGetNewResponse(data.dados);
    }
    function updateTopicoEvent(data) {
      setGetEditComment(data.dados);
      console.log("update socket");
    }
    function deleteTopicoEvent(data) {
      setGetDeleteComment(data.dados);
      console.log("delete socket");
    }
    function fixTopicoEvent(data) {
      setGetFixComment(data.dados);
      console.log("fix socket");
    }

    socket.on(`createChatTopico`, chatEvent);
    socket.on(`updateTopicoRelatorio`, updateTopicoEvent);
    socket.on(`deleteTopicoRelatorio`, deleteTopicoEvent);
    socket.on(`fixTopicoRelatorio`, fixTopicoEvent);
    return () => {
      socket.off(`createChatTopico`, chatEvent);
      socket.off(`updateTopicoRelatorio`, updateTopicoEvent);
      socket.off(`deleteTopicoRelatorio`, deleteTopicoEvent);
      socket.off(`fixTopicoRelatorio`, fixTopicoEvent);
    };
  }, [socket]);
  useEffect(() => {
    if (getNewResponse && getNewResponse.topicoRelatorioId === comentario.id) {
      let newResponse = formataRespostasComentario(getNewResponse);
      const auxResposta = [...respostas, newResponse];
      setRespostas(auxResposta);
    }
  }, [getNewResponse]);
  useEffect(() => {
    if (getEditComment) {
      updateComentario(formataComentario(getEditComment));
    }
  }, [getEditComment]);
  useEffect(() => {
    if (getDeleteComment && comentario.id === getDeleteComment.id) {
      atualizaBadgeTopicos(-1);
      deleteComentario(getDeleteComment);
    }
  }, [getDeleteComment]);
  useEffect(() => {
    if (getFixComment) {
      fixarComentario(getFixComment.id, getFixComment.fixar);
    }
  }, [getFixComment]);
  useEffect(() => {
    setImg(comentario.imagens[0]?.foto);
  }, [comentario]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setImagens(comentario.imagens);
    // Função para agrupar as fotos em grupos de cinco
    const agruparFotos = () => {
      const novoGruposDeFotos = [];

      for (let i = 0; i < comentario.imagens?.length; i += 4) {
        novoGruposDeFotos.push(comentario.imagens.slice(i, i + 4));
      }
      return novoGruposDeFotos;
    };
    // Atualiza o estado dos grupos de fotos
    setImagensAgrupadas(agruparFotos());
    //setfotoSelecionada({url:foto[0]?.foto, id:0})
  }, [comentario]);

  async function processarImagens(files) {
    const imagens = [];

    for (let i = 0; i < files.length; i++) {
      const uri = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          files[i],
          525,
          350,
          "JPEG",
          80,
          0,
          (resizedUri) => {
            resolve(resizedUri);
          },
          "base64",
          350,
          350
        );
      });

      imagens.push({ foto: uri, nome: files[i].name });
    }
    setImagens((imagensAntigas) => [...imagensAntigas, ...imagens]);
  }
  useEffect(() => {
    const paragraph = document.getElementById("myParagraph");
    if (paragraph.scrollHeight > paragraph.clientHeight) {
      //  setShowButtonMoreDesc(true);
    } else {
      //  setShowButtonMoreDesc(false);
    }
  }, [comentario]);

  function formataRespostasComentario(topic) {
    let obj = {};
    obj.id = topic.id;
    obj.fixar = topic.fixar;
    obj.data = new Date(topic.createAt);
    obj.dataUpdate = topic.updateAt ? new Date(topic.updateAt) : null;
    obj.usuarioId = topic.usuarioId;
    obj.usuarioClienteId = topic.usuarioClienteId;
    obj.usuario = topic.usuario;
    obj.usuarioCliente = topic.usuarioCliente;
    obj.texto = topic.texto;
    return obj;
  }
  function updateRespostas(newResponse) {
    const novoArray = respostas.map((resposta) => {
      if (resposta.id === newResponse.id) {
        let auxResposta = newResponse;
        return { ...auxResposta };
      } else {
        return resposta;
      }
    });
    setRespostas(novoArray);
  }
  function deleteResposta(deletedResponse) {
    const novoArray = respostas.filter(
      (respostas) => respostas.id !== deletedResponse.id
    );
    setRespostas(novoArray);
  }
  function handleTime(params) {
    const agora = new Date();
    const segundos = parseInt((Date.parse(agora) - Date.parse(params)) / 1000);
    const horas = Math.floor(segundos / (60 * 60));
    const dias = horas > 24 && Math.floor(horas / 24);
    const minutos = Math.floor(segundos / 60) % 60;
    if (dias > 0) {
      if (dias <= 1) {
        return parseInt(dias) + " dia atrás";
      }
      return parseInt(dias) + " dias atrás";
    } else if (horas > 0) {
      if (horas <= 1) {
        return parseInt(horas) + " h atrás";
      } else {
        return parseInt(horas) + " hrs atrás";
      }
    } else if (minutos > 0) {
      return parseInt(minutos) + " min atrás";
    } else {
      return "segs atrás";
    }
  }

  function clearCardComentario() {
    setNovaResposta("");
  }

  function fixarResposta(respostaId, fixar) {
    const fixedArray = respostas.map((resposta) => {
      if (resposta.id === respostaId) {
        let auxResposta = resposta;
        auxResposta.fixar = fixar;
        return auxResposta;
      } else {
        return resposta;
      }
    });
    fixedArray.sort(function (a, b) {
      if (a.fixar > b.fixar) {
        return -1;
      }
      if (a.fixar < b.fixar) {
        return 1;
      }
      //se os dois sao iguais (fixados ou nao) compara a data
      if (a.data > b.data) {
        return -1;
      }
      if (a.data < b.data) {
        return 1;
      }
      return 0;
    });
    setRespostas(fixedArray);
  }
  async function handleApiPutTopico() {
    try {
      //setLoadingChat(true);
      const response = await api.put("/suporte/topico/update", {
        texto: editaComentario,
        id: comentario.id,
        midia: Array.isArray(imagens) ? imagens : null,
        logsLoginId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.data.status === "Error") {
        //setLoadingChat(false);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        //setLoadingChat(false);
        if (response.data) {
          // updateComentario(formataComentario(response.data));
        }
      }
    } catch (error) {
      // setLoadingChat(false);
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  async function handleApiPostChat() {
    try {
      //setLoadingChat(true);
      const response = await api.post("/suporte/topico/chat/create", {
        texto: novaResposta,
        topicoRelatorioId: comentario.id,
        logsLoginId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.data.status === "Error") {
        // setLoadingChat(false);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        // setLoadingChat(false);
        clearCardComentario();
        if (response.data) {
          //  let newResponse = formataRespostasComentario(response.data);
          //  const auxResposta = [...respostas, newResponse];
          // setRespostas(auxResposta);
        }
      }
    } catch (error) {
      // setLoadingChat(false);
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }

  async function handleApiDelete() {
    try {
      //setLoadingChat(true);
      setOpenDeleteModal(true);
      const response = await api.put("/suporte/topico/delete", {
        id: comentario.id,
        logsLoginId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.data.status === "Error") {
        //setLoadingChat(false);
        setOpenDeleteModal(false);
        setConfirmarDelete(false);
        setSnackbar({
          children: "Error: Não foi possível apagar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados excluídos com sucesso",
          severity: "success",
        });
        //setLoadingChat(false);
        setOpenDeleteModal(false);
        setConfirmarDelete(false);
        if (response.data) {
          // atualizaBadgeTopicos(-1);
          // deleteComentario(response.data);
        }
      }
    } catch (error) {
      // setLoadingChat(false);
      setOpenDeleteModal(false);
      setConfirmarDelete(false);
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  async function handleApiFixarTopico(fixar) {
    try {
      //setLoadingChat(true);
      const response = await api.put("/suporte/topico/fix", {
        id: comentario.id,
        fixar,
        logsLoginId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.data.status === "Error") {
        //setLoadingChat(false);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        //setLoadingChat(false);
        if (response.data) {
          // fixarComentario(response.data.id, response.data.fixar);
        }
      }
    } catch (error) {
      // setLoadingChat(false);
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  return (
    <>
      <Paper sx={stylesCardComentario.paperChat} variant="outlined">
        <Avatar
          sx={stylesCardComentario.avatarComment}
          src={
            comentario.usuarioCliente?.PerfilCliente?.foto ||
            comentario.usuario?.Perfil?.foto
          }
        >
          {comentario.usuario?.Perfil?.nome?.trim().toUpperCase()[0] ||
            comentario.usuarioCliente?.PerfilCliente?.nomeFantasia
              ?.trim()
              .toUpperCase()[0] ||
            comentario.usuarioCliente?.PerfilCliente?.nome
              ?.trim()
              .toUpperCase()[0] ||
            "U"}
        </Avatar>
        {!editar && (
          <Stack direction={"column"} gap={"5px"}>
            <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
              <Stack direction={"row"} gap={"5px"}>
                <Typography
                  variant="h4"
                  component={"div"}
                  gutterBottom
                  sx={{
                    fontSize: "1.0rem",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}
                  color="primary"
                >
                  {comentario.usuario?.Perfil?.nome
                    ? comentario.usuario?.Perfil?.nome
                    : comentario.usuarioCliente?.PerfilCliente.pessoaFisica
                    ? comentario.usuarioCliente?.PerfilCliente?.nome
                    : comentario.usuarioCliente?.PerfilCliente?.nomeFantasia ||
                      "Usuário sem nome"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    mt: 0.1,
                  }}
                >
                  há {handleTime(comentario.data)}
                  {comentario.dataUpdate ? " (editado)" : ""}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                gap={"5px"}
                sx={{ alignItems: "center", mr: "-20px", mt: "-20px" }}
              >
                {comentario?.fixar && (
                  <Chip
                    label="Fixado"
                    icon={<PushPin color="white" />}
                    sx={{
                      padding: "5px",
                      bgcolor: "chip.waitingReturn",
                      color: "white",
                    }}
                    //color="chip.waitingReturn"
                  />
                )}

                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "15ch",
                    },
                  }}
                >
                  <MenuItem
                    key="op1"
                    onClick={() => {
                      handleApiFixarTopico(!comentario.fixar);
                      handleCloseMenu();
                    }}
                  >
                    <PushPin sx={{ mr: 1, color: "primary.main" }} />
                    {comentario.fixar ? "Desafixar" : "Fixar"}
                  </MenuItem>

                  {comentario?.usuarioId === usuarioId && <Divider />}
                  {comentario?.usuarioId === usuarioId && (
                    <MenuItem
                      key="op2"
                      onClick={() => {
                        setEditar(true);
                        setEditaComentario(comentario.texto);
                        handleCloseMenu();
                      }}
                    >
                      <Edit sx={{ mr: 1, color: "primary.main" }} />
                      Editar
                    </MenuItem>
                  )}

                  {comentario?.usuarioId === usuarioId && <Divider />}
                  {comentario?.usuarioId === usuarioId && (
                    <MenuItem
                      key="op3"
                      onClick={() => {
                        handleCloseMenu();
                        setOpenDeleteModal(true);
                      }}
                    >
                      <Delete sx={{ mr: 1, color: "secondary.main" }} />
                      Deletar
                    </MenuItem>
                  )}
                </Menu>
              </Stack>
            </Stack>
            <Stack sx={{ width: "600px" }}>
              <Typography
                dangerouslySetInnerHTML={{ __html: comentario.texto }}
                id="myParagraph"
                sx={[
                  {
                    mt: "-10px",
                    fontSize: "0.95rem",
                    fontWeight: "400",
                    textAlign: "justify",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                  },
                  showMoreDesc ? {} : { WebkitLineClamp: 5 },
                ]}
              ></Typography>
              {showButtonMoreDesc && (
                <>
                  <Button
                    sx={stylesCardComentario.buttonShowMore}
                    onClick={() => setShowMoreDesc(!showMoreDesc)}
                  >
                    {showMoreDesc ? <p>Mostrar menos</p> : <p>Mostrar mais</p>}
                  </Button>
                </>
              )}
            </Stack>

            <Carousel
              visibleItems={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              animation="slide"
              autoPlay={false}
              cycleNavigation={false}
              navButtonsAlwaysVisible={true}
              indicators={false}
            >
              {imagensAgrupadas.map((grupos, i) => (
                <Grid
                  key={"nuuc" + i}
                  container
                  spacing={1}
                  sx={{ marginLeft: "50px" }}
                >
                  {imagensAgrupadas[i].map((f, index) => (
                    <Grid key={"nuc" + index} item xs={2}>
                      {imageError ? (
                        <Box
                          sx={{
                            width: "60px",
                            height: "70px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor:
                              theme.palette.mode === "dark"
                                ? "rgba(255, 255, 255, 0.15)"
                                : "rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <FileNotFound
                            color="secondary"
                            sx={{ width: "50px", height: "auto" }}
                          />
                        </Box>
                      ) : (
                        <Button
                          onClick={() => {
                            setOpenModalFoto(true);
                          }}
                          sx={{ width: "100px", height: "75px" }}
                        >
                          <Box
                            component="img"
                            src={f.foto}
                            alt="Imagem"
                            maxWidth="90px"
                            height="60px"
                            sx={{ background: "red" }}
                          />
                        </Button>
                      )}
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Carousel>
            {!responder && (
              <Button
                //variant="text"
                onClick={() => {
                  setResponder(true);
                }}
                startIcon={<CommentIcon />}
                sx={{
                  width: "90px",
                  fontSize: "0.9rem",
                  //textTransform: "none",
                  mt: -1.5,
                  color: "primary.main",
                  ml: 1,
                }}
              >
                Responder
              </Button>
            )}

            {Array.isArray(respostas) ? (
              <Box sx={{ mt: "10px" }}>
                {respostas.map((resposta, index) => (
                  <CardResposta
                    key={resposta.id}
                    resposta={formataRespostasComentario(resposta)}
                    usuarioId={usuarioId}
                    setSnackbar={setSnackbar}
                    formataRespostasComentario={formataRespostasComentario}
                    updateRespostas={updateRespostas}
                    deleteResposta={deleteResposta}
                    fixarResposta={fixarResposta}
                    socket={socket}
                  />
                ))}
              </Box>
            ) : null}
            {responder && (
              <Box
                sx={{
                  width: "100%",
                  alignItems: "start",
                  gap: "20px",
                  display: "flex",
                  flexDirection: "row",
                  elevation: 0,
                  bgcolor: "background.paperChat",
                }}
              >
                <Avatar
                  sx={stylesCardComentario.avatarResponse}
                  src={fotoUsuario}
                >
                  {nomeUsuario?.trim().toUpperCase()[0] || "U"}
                </Avatar>
                {false && (
                  <>
                    <TextField
                      id="input-resposta"
                      label="Responder o tópico"
                      value={""}
                      sx={{ width: "100%" }}
                      onChange={() => {}}
                      onClick={() => {
                        setResponder(true);
                      }}
                    />
                  </>
                )}
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Editor
                      value={novaResposta}
                      onChange={(value) => {
                        setNovaResposta(value);
                        if (!value) {
                          setDisableButtonChat(true);
                        } else {
                          setDisableButtonChat(false);
                        }
                      }}
                    />{" "}
                    <Box
                      sx={{
                        width: "98%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        onClick={() => {
                          clearCardComentario();
                          setResponder(false);
                        }}
                        color="secondary"
                      >
                        Cancelar
                      </Button>
                      <Button
                        disabled={disableButtonChat}
                        onClick={() => {
                          setResponder(false);
                          clearCardComentario();
                          handleApiPostChat();
                        }}
                      >
                        Enviar
                      </Button>
                    </Box>
                  </Box>
                </>
              </Box>
            )}
          </Stack>
        )}
        {editar && (
          <Stack
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Editor
                  value={editaComentario}
                  onChange={(value) => {
                    setEditaComentario(value);
                    if (!value || value === "<p><br></p>") {
                      setDisabledButtonEdit(true);
                    } else {
                      setDisabledButtonEdit(false);
                    }
                  }}
                />
                <Tooltip title="Adicionar Imagem">
                  <IconButton component="label" sx={{ ml: 1 }}>
                    <AttachFile />
                    <input
                      hidden
                      multiple
                      accept=".png, .jpg, .jpeg"
                      type="file"
                      onChange={(e) => {
                        const files = e.target.files;
                        processarImagens(files);
                      }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </Box>
              {imagens?.map((img, index) => (
                <Box sx={{ width: "85%", ml: "5%", mt: 1 }}>
                  <Typography sx={{ fontSize: "0.75rem" }}>
                    {img.nome ? img.nome : `Imagem ${index + 1}`}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: "-17px",
                      mb: "-17px",
                    }}
                  >
                    <LinearProgress
                      sx={{ width: "100%" }}
                      variant="determinate"
                      value={100}
                    />
                    <IconButton onClick={() => removerImagem(index)}>
                      <Close sx={{ width: "20px" }} />
                    </IconButton>
                  </Box>
                  <Typography sx={{ fontSize: "0.75rem" }}>Completo</Typography>
                </Box>
              ))}
              <Box
                sx={{
                  width: "92%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  onClick={() => {
                    clearCardComentario();
                    setEditar(false);
                  }}
                  color="secondary"
                >
                  Cancelar
                </Button>
                <Button
                  disabled={disabledButtonEdit}
                  onClick={() => {
                    setDisabledButtonEdit(true);
                    handleApiPutTopico(comentario.id);
                    setEditar(false);
                  }}
                >
                  Comentar
                </Button>
              </Box>
            </Box>
          </Stack>
        )}
      </Paper>{" "}
      <Modal
        open={openModalFoto}
        onClose={() => setOpenModalFoto(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          sx={{
            height: "80vh",
            margin: "10px",
            display: "flex",
            flexDirection: "row",
            padding: "20px",
            justifyContent: "center",
          }}
        >
          <Box
            width="60%"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box component="img" src={img} height="500px" maxWidth="90%" />
          </Box>
          <Box
            width="120px"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <IconButton
              onClick={() => setOpenModalFoto(false)}
              sx={{ marginLeft: "93%", width: "40px", marginTop: "-10px" }}
            >
              <Close />
            </IconButton>

            <Grid
              container
              sx={{
                marginTop: "20px",
                flexDirection: "column",
              }}
              spacing={1}
            >
              {comentario.imagens.map((imgProduct, i) => (
                <Grid key={"fotos" + i} item xs={6} md={3}>
                  <Button sx={{ "&:hover": { background: "none" } }}>
                    <Box
                      component="img"
                      src={imgProduct.foto}
                      height="100%"
                      width="100px"
                      onClick={() => setImg(imgProduct.foto)}
                      onMouseEnter={() => setImg(imgProduct.foto)}
                      padding="5px"
                      sx={{
                        border: "1px solid rgba(0,0,0,0.5)",

                        "&:hover": {
                          border: "1px solid #5B2E87",
                          transform: "scale(1.2)",
                        },
                      }}
                    />
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Modal>
      <DeleteDialog
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        confirmarDelete={confirmarDelete}
        handleApiDelete={handleApiDelete}
      />
    </>
  );
}
