import { Add, Delete, Edit } from "@mui/icons-material";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { useState } from "react";
import DeleteDialog from "../../../../../../../componentes/deleteDialog";
import TreeItemModelo from "./treeItemModelo";
import api from "../../../../../../../services/api";
import { TreeItem } from "@mui/x-tree-view";

export default function TreeItemFuncao(props) {
  const {
    nodeId,
    label,
    node,
    updateFuncoes,
    setPredefinicaoId,
    setNomeModelo,
    deleteFuncao,
    createPredefinicoes,
    expandeNovoModelo,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  //
  const [funcao, setFuncao] = useState(label);
  const [modelo, setModelo] = useState("");
  const [modelos, setModelos] = useState([...node.Modelos]);
  //
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const confirmarDelete = false;
  //
  const [snackbar, setSnackbar] = useState(null);
  //
  function createModelos(novoModelo) {
    const novoArray = [...modelos];
    novoArray.push(novoModelo);
    setModelos(novoArray);
    expandeNovoModelo(novoModelo, node.id);
  }

  function updateModelos(novoModelo) {
    const novoArray = modelos.map((obj) => {
      if (obj.id === novoModelo.id) {
        return { ...obj, ...novoModelo };
      } else {
        return obj;
      }
    });
    setModelos(novoArray);
  }

  function deleteModelo(removeModelo) {
    const novoArray = modelos.map((obj) => {
      if (obj.id !== removeModelo.id) {
        return obj;
      } else {
        return { id: null, modelo: null, Predefinicoes: [] };
      }
    });
    setModelos(novoArray);
  }

  const handleApiDelete = async () => {
    try {
      const response = await api.post("/deleteFuncao", {
        id: nodeId,
      });
      if (response.data.status === "Error") {
        setOpenDeleteModal(false);
        setSnackbar({
          children: "Error: Não foi possível excluir a função",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        setFuncao(null);
        setOpenDeleteModal(false);
        deleteFuncao(node);
      }
    } catch (error) {
      console.error(error);
      setOpenDeleteModal(false);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  };
  const handleApiEditFuncao = async () => {
    try {
      const response = await api.put("/putFuncao", {
        funcao,
        id: nodeId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível editar a função",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        updateFuncoes(response.data);
      }
    } catch (error) {
      console.error(error);

      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  };
  async function handleApiCriaModelo() {
    try {
      const response = await api.post("/postModelo", {
        modelo,
        funcaoId: nodeId,
      });
      if (response.data.status === "Error") {
        setModelo("");
        setSnackbar({
          children: "Error: Não foi possível criar o modelo",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        setModelo("");
        createModelos(response.data);
        createPredefinicoes(response.data.Predefinicoes);
      }
    } catch (error) {
      console.error(error);
      setModelo("");
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }

  const handleContextMenu = (event, node) => {
    event.preventDefault();
    event.stopPropagation();
    if (!openCreateModal && !openEditModal && !openDeleteModal) {
      if (
        menuPosition === null ||
        (menuPosition?.top === 0 && menuPosition?.left === 0)
      ) {
        setAnchorEl(event.currentTarget);
        setMenuPosition({ top: event.pageY, left: event.pageX });
      } else {
        handleCloseMenu();
        setAnchorEl("");
      }
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuPosition(null);
  };

  return (
    <div>
      {funcao ? (
        <div onContextMenu={(event) => handleContextMenu(event, node.id)}>
          <TreeItem
            style={{ display: "flex", flexDirection: "column" }}
            nodeId={nodeId}
            label={funcao}
            itemId={nodeId}
            aria-controls="simple-menu"
          >
            {Array.isArray(modelos)
              ? modelos.map((modelo, index) => (
                  <TreeItemModelo
                    id={modelo.id}
                    key={index}
                    nodeId={modelo.id}
                    label={modelo.modelo}
                    node={modelo}
                    deleteModelo={deleteModelo}
                    setPredefinicaoId={setPredefinicaoId}
                    updateModelos={updateModelos}
                    setNomeModelo={setNomeModelo}
                  />
                ))
              : null}
          </TreeItem>

          <Menu
            id="simple-menu"
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorReference="anchorPosition"
            anchorPosition={menuPosition}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setOpenCreateModal(true);
              }}
            >
              <ListItemIcon>
                <Add color="primary" />
              </ListItemIcon>
              <ListItemText>Adicionar Modelo </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setOpenEditModal(true);
              }}
            >
              <ListItemIcon>
                <Edit color="primary" />
              </ListItemIcon>
              <ListItemText>Editar Função</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setOpenDeleteModal(true);
              }}
            >
              {" "}
              <ListItemIcon>
                <Delete color="secondary" />
              </ListItemIcon>
              <ListItemText>Excluir Função</ListItemText>
            </MenuItem>
          </Menu>
          <Dialog
            open={openCreateModal}
            onClose={() => {
              setOpenCreateModal(false);
              setMenuPosition(null);
            }}
          >
            <DialogTitle color={"primary"}>Adicionar Modelo</DialogTitle>
            <DialogContent>
              <TextField
                id="modelo"
                variant="standard"
                label="Modelo"
                value={modelo}
                onChange={(event) => {
                  setModelo(event.target.value);
                }}
                sx={{ width: "240px" }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                onClick={() => {
                  setOpenCreateModal(false);
                  setModelo("");
                  setMenuPosition(null);
                }}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={async () => {
                  handleApiCriaModelo();
                  setOpenCreateModal(false);
                  setMenuPosition(null);
                }}
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openEditModal}
            onClose={() => {
              setOpenEditModal(false);
              setMenuPosition(null);
            }}
          >
            <DialogTitle color={"primary"}>Editar função</DialogTitle>
            <DialogContent>
              <TextField
                id="funcao"
                variant="standard"
                label="Função"
                value={funcao}
                onChange={(event) => {
                  setFuncao(event.target.value);
                }}
                sx={{ width: "240px" }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                onClick={() => {
                  setOpenEditModal(false);
                  setFuncao(label);
                  setMenuPosition(null);
                }}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  handleApiEditFuncao();
                  setOpenEditModal(false);
                  setMenuPosition(null);
                }}
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
          <DeleteDialog
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            confirmarDelete={confirmarDelete}
            handleApiDelete={handleApiDelete}
          />
          {!!snackbar && (
            <Snackbar
              open
              onClose={() => setSnackbar(null)}
              autoHideDuration={2000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert {...snackbar} onClose={() => setSnackbar(null)} />
            </Snackbar>
          )}
        </div>
      ) : null}
    </div>
  );
}
