import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../../services/api";

export default function EditModalBlocos(params) {
  const {
    setOpenEditModal,
    openEditModal,
    setSnackbar,
    setLoadingTable,
    styles,
    selectedRow,
    setErrorName,
    errorName,
    comparaOutrosNomes,
    handleEditBlocos,
    validarIp,
  } = params;
  const [nome, setNome] = useState("");
  const [listaBloco, setListaBloco] = useState("");
  const [disableEditButton, setDisableEditButton] = useState(false);
  const validarIps = (ips) => {
    const arrayDeIps = ips?.split(",");
    let verificaIps = false;
    if (Array.isArray(arrayDeIps) && arrayDeIps.length > 0) {
      verificaIps = arrayDeIps.some((ip) => !validarIp(ip));
    }
    return verificaIps;
  };
  const handleHelperText = (ips) => {
    if (ips) {
      return validarIps(ips)
        ? "Formato de algum(s) do(s) IP(s) é inválido"
        : "Os endereços são válidos!";
    }
    return null;
  };
  useEffect(() => {
    if (selectedRow) {
      setNome(selectedRow.nome);
      setListaBloco(selectedRow.blocos);
    }
  }, [selectedRow]);

  useEffect(() => {
    if (
      comparaOutrosNomes(nome, selectedRow.nome) ||
      validarIps(listaBloco) ||
      !listaBloco
    ) {
      setDisableEditButton(true);
    } else {
      setDisableEditButton(false);
    }
  }, [nome, listaBloco]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableEditButton === false) {
      handleApiPutBlocos();
    }
  };
  async function handleApiPutBlocos() {
    try {
      setLoadingTable(true);
      setDisableEditButton(true);
      const response = await api.put("/relatoriosFlow/blocos/update", {
        id: selectedRow.id,
        nome,
        blocos: listaBloco,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleEditBlocos(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableEditButton(false);
      setOpenEditModal(false);
    }
  }
  return (
    <>
      <Modal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
          setErrorName(false);
        }}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Edite Bloco </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            {" "}
            <Stack direction="column" spacing={"20px"}>
              <TextField
                sx={styles.TextField}
                id="textfield-nome"
                label="Nome"
                variant="standard"
                value={nome}
                error={errorName}
                helperText={errorName ? "Nome é uma chave única" : null}
                onChange={(e) => {
                  if (comparaOutrosNomes(e.target.value, selectedRow.nome)) {
                    setErrorName(true);
                  } else {
                    setErrorName(false);
                  }
                  setNome(e.target.value);
                }}
              />
              <TextField
                sx={styles.TextField}
                id="textfield-blocos"
                label="Bloco(s)"
                variant="standard"
                onKeyDown={handleKeyDown}
                value={listaBloco}
                error={listaBloco ? validarIps(listaBloco) : false}
                helperText={handleHelperText(listaBloco)}
                onChange={(e) =>
                  setListaBloco(e.target.value.replace(/\s/g, ""))
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setOpenEditModal(false);
                setErrorName(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableEditButton}
              onClick={() => {
                handleApiPutBlocos();
              }}
            >
              Editar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
    </>
  );
}
