import { Close, Dangerous, Delete, Edit, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import { useState } from "react";
import api from "../../../../../../services/api";

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    width: "52vh",

    padding: "20px",
    justifyContent: "center",
  },
  TextField: {
    width: "200px",
  },
  DialogTitle: { color: "primary.main" },
};

export default function EditProduct(props) {
  const { produto, atualizarProduto, excluirProduto } = props;
  const [snackbar, setSnackbar] = useState(null);
  const [editable, setEditable] = useState(true);
  const [nomeEdit, setNomeEdit] = useState(produto.nome);
  const [tipo, setTipo] = useState(produto.tipo);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  async function handleApiEdit() {
    try {
      const response = await api.put("/putProdutos", {
        id: produto.id,
        nome: nomeEdit,
        tipo,
      });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });

        setOpenEditModal(false);
        let aux = response.data;
        aux = { ...produto, ...aux };
        atualizarProduto(aux);
        setEditable(true);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      console.error(error);
    }
  }

  async function handleApiDelete() {
    try {
      const response = await api.post("/deleteProdutos", {
        id: produto.id,
      });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível excluir dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });

        excluirProduto(response.data);
        setOpenDeleteModal(false);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      console.error(error);
    }
  }

  return (
    <Stack
      direction={"row"}
      spacing={2}
      alignItems="center"
      justifyContent="flex-start"
    >
      <TextField
        variant="standard"
        label="Nome"
        sx={styles.TextField}
        value={nomeEdit}
        onChange={(event) => {
          setNomeEdit(event.target.value);
        }}
        disabled={editable}
      />
      <FormControl
        sx={{ width: "100px" }}
        variant="standard"
        disabled={editable}
      >
        <InputLabel>Tipo</InputLabel>
        <Select
          value={tipo}
          label="Tipo"
          onChange={(event) => {
            setTipo(event.target.value);
          }}
        >
          <MenuItem value={"easymon"}>EasyMon</MenuItem>
          <MenuItem value={"easyflow"}>EasyFlow</MenuItem>
        </Select>
      </FormControl>
      <Box>
        {editable && (
          <>
            <Tooltip
              title={"Editar"}
              onClick={() => {
                setEditable(!editable);
              }}
            >
              <IconButton>
                <Edit color={"primary"} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={"Excluir"}
              onClick={() => {
                setOpenDeleteModal(true);
              }}
            >
              <IconButton>
                <Delete color={"secondary"} />
              </IconButton>
            </Tooltip>
          </>
        )}
        {!editable && (
          <>
            <Tooltip
              title={"Salvar"}
              onClick={() => {
                setOpenEditModal(true);
              }}
            >
              <IconButton>
                <Save color={"primary"} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={"Cancelar"}
              onClick={() => {
                setNomeEdit(produto.nome);
                setTipo(produto.tipo);
                setEditable(true);
              }}
            >
              <IconButton>
                <Close color={"secondary"} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>

      <Dialog
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
      >
        <DialogTitle>{"Deseja realmente salva os dados editados?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Uma vez após editado os dados anteriores não poderem ser
            recuperados. Tem certeza que de deseja fazer essa edição ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              setOpenEditModal(false);
            }}
          >
            Cancelar
          </Button>

          <Button autoFocus onClick={handleApiEdit}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
      >
        <DialogTitle sx={{ color: "error.main" }}>
          Deseja realmente <span style={{ fontWeight: "900" }}>excluir</span>{" "}
          esses dados?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Dangerous color={"error"} sx={{ fontSize: 60 }} />
            </Box>
            Uma vez após excluidos os dados não podem ser recuperados. Tem
            certeza que de deseja fazer essa exclusão ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteModal(false);
            }}
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            color={"error"}
            sx={{ width: "100px", fontWeight: "800" }}
            onClick={handleApiDelete}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Stack>
  );
}
