import { useCallback, useState } from "react";
import {
  Box,
  Stack,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  Snackbar,
  Alert,
  useTheme,
  Chip,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  CancelOutlined,
  CheckCircle,
  Delete,
  Edit,
  Error,
  RemoveCircle,
  SaveOutlined,
} from "@mui/icons-material";
import { DataGrid, useGridApiContext } from "@mui/x-data-grid";

import fabricantes from "../fabricantes.json";

import api from "../../../../../services/api";
import CopyModal from "../copyModal/copyModal";
import AlertModal from "../../../../../componentes/alertModal";
import EditcellTextField from "../../../../../componentes/easyAdd/editCellText";
import EditCellAutoComplete from "../../../../../componentes/easyAdd/editCellAutoComplete";
import {
  CellExibition,
  CellExibitionAlerts,
} from "../tabelaPreCadastrados/exibitionCell";
import { validateIp } from "../../../../../utils/validateIp";
import { validateTags } from "../../../../../utils/easyAdd/validateTags";
import { validateColetor } from "../../../../../utils/easyAdd/validateColetor";
import EditCellAutoCompleteInterface from "../../../../../componentes/easyAdd/editCellAutoCompleteInterface";
import { ptBR } from "@mui/x-data-grid/locales";
export default function TabelaCadastrados(params) {
  const {
    cliente,
    rows,
    setRows,
    predefinicoes,
    acessoProdutoId,
    acessoClientesId,
    proxys,
  } = params;
  const [nome, setNome] = useState("");
  const [ip, setIp] = useState("");
  const [fabricante, setFabricante] = useState("");
  const [coletor, setColetor] = useState("");
  const [funcao, setFuncao] = useState("");
  const [error, setError] = useState("");
  const [snackbar, setSnackbar] = useState(null);
  const [disableButton, setDissableButton] = useState(false);
  const [disableButtonDelete, setDissableButtonDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  //modal
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  //menu
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const tiposSNMP = [{ tipoSnmp: "SNMPv1" }, { tipoSnmp: "SNMPv2" }];
  const tiposInterface = [{ interface: "AGENTE" }, { interface: "SNMP" }];

  const styles = {
    container: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "30px",
    },
    paper: {
      width: "100%",
      minWidth: "900px",
      padding: "20px",
      gap: "20px",
      display: "flex",
      flexDirection: "column",
      borderRadius: 3,
      elevation: 3,
    },

    button: { height: "40px", minWidth: "120px", m: 1 },
    filter: { width: "180px" },
    deleButton: { width: "100px", fontWeight: "800" },
    dangerousIcon: { fontSize: 60 },
    boxDialogs: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
    },
    dialogText: { color: "#D32F2F" },
    titles: { fontWeight: 500, fontSize: "20px" },
    dataGrid: {
      fontSize: 13,
      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
      },
      height: "100%",
      width: "100%",

      "& .Mui-error": {
        backgroundColor: `rgb(126,10,15, ${
          theme.palette.mode === "dark" ? 0 : 0.1
        })`,
        color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
      },

      "& .MuiDataGrid-cell": {
        position: "relative",
        display: "flex",
        alignItems: "center",
        "&::after": {
          content: '""',
          position: "absolute",
          right: 0,
          top: "25%", // 10% da parte superior para centralizar a borda
          height: "50%", // 80% da altura da célula
          width: "1px",
          backgroundColor: theme.palette.color.textDisable, // Cor da borda
        },
      },
    },
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    interfaceid: false,
    // senhaTelnet: false,
    // portaTelnet: false,
    // userTelnet: false,
    // tipoSnmp: false,
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleApiDelete(id) {
    try {
      //console.log(selectedRow);
      const response = await api.post("zabbixDeleteHost", {
        id,
        acessoClientesId,
        acessoProdutoId,
      });

      if (response.data.status === "Error") {
        //console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível excluir dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados excluídos com sucesso",
          severity: "success",
        });
        setRows((data) => data.filter((a) => a.id != id));
        setOpenDeleteDialog(false);
        handleClose();
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
    }
  }

  const filteredRows = rows.filter((row, index) => {
    row.numero = index;
    //console.log("text", row);
    return (
      row.nome.toLowerCase().includes(nome.toLowerCase()) &&
      row.ip.toLowerCase().includes(ip.toLowerCase()) &&
      row.fabricante.toLowerCase().includes(fabricante.toLowerCase()) &&
      row.funcao.toLowerCase().includes(funcao.toLowerCase()) &&
      row.coletor.toLowerCase().includes(coletor.toLowerCase())
    );
  });

  const camposNulos = (host) => {
    return Object.keys(host).every((key) => {
      if (
        key !== "id" &&
        key !== "actions" &&
        key != "ping" &&
        key != "snmp" &&
        key != "portaSsh" &&
        key != "userSsh" &&
        key != "senhaSsh" &&
        key != "portaTelnet" &&
        key != "userTelnet" &&
        key != "senhaTelnet" &&
        key !== "comunidade" &&
        key != "numero"
      ) {
        return !!host[key];
      } else return true;
    });
  };
  const handleValidateData = useCallback((host) => {
    let verify =
      validateColetor(proxys, host) &&
      validateTags(predefinicoes, host) &&
      camposNulos(host) &&
      !!host?.nome &&
      !!host?.ip &&
      validateIp(host?.ip) &&
      validaColunaNome({ value: host?.nome, id: host?.id }) == null &&
      host?.interface == "SNMP"
        ? tiposSNMP.some((a) => a.tipoSnmp == host?.tipoSnmp)
        : true &&
          tiposInterface.some((a) => a.interface === host?.interface) &&
          !!host?.interface;
    if (!verify) {
      setSnackbar({
        severity: "error",
        children: "Alguns campos não foram preenchidos corretamente",
      });
    }

    return verify;
  }, []);

  function ActionsEdit({ id }) {
    const apiRef = useGridApiContext();

    const setRowEditMode = () => {
      let line = apiRef?.current?.getRowWithUpdatedValues(id);
      if (!handleValidateData(line)) return;
      apiRef.current.stopRowEditMode({ id });
    };
    const cancel = () => {
      apiRef.current.stopRowEditMode({ id, ignoreModifications: true });
    };
    return (
      <>
        <Divider
          orientation="vertical"
          sx={{
            ml: "-10px",
            mr: "10px",
            height: "50%",
            "&.MuiDivider-root": { opacity: 0.5 },
          }}
        />

        <Tooltip title="Salvar">
          <IconButton disabled={false} onClick={setRowEditMode}>
            <SaveOutlined fontSize="small" color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancelar">
          <IconButton disabled={false} onClick={cancel}>
            <CancelOutlined fontSize="small" color="secondary" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  function Actions({ id }) {
    const apiRef = useGridApiContext();
    const setRowEditMode = () => {
      apiRef.current.startRowEditMode({ id });
    };
    return (
      <>
        <Divider
          orientation="vertical"
          sx={{
            ml: "-10px",
            mr: "10px",
            height: "50%",
            "&.MuiDivider-root": { opacity: 0.5 },
          }}
        />

        <Tooltip title="Editar">
          <IconButton disabled={false} onClick={setRowEditMode}>
            <Edit fontSize="small" color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Excluir">
          <IconButton
            disabled={false}
            onClick={() => setOpenDeleteDialog({ open: true, id })}
          >
            <Delete fontSize="small" color="secondary" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  const handleChangeIp = (event) => {
    const newIp = event.target.value;
    setIp(newIp);
    //setError(validateIp(newIp));
  };

  const validaColunaNome = useCallback(
    (params) => {
      let handleError = params.error
        ? params.error
        : !params?.value
        ? "Campo obrigatório"
        : null;

      const comparaNomes = rows?.some(
        ({ id, nome }) => params.value === nome && params.id !== id
      );
      if (comparaNomes)
        handleError = "Já existe um host com esse nome cadastrado";
      return handleError;
    },
    [rows]
  );

  function validaColObrigatoria(params) {
    let errorMessage = null;
    errorMessage =
      !params?.value || params?.value == "-" ? "Campo obrigatório" : null;
    return errorMessage;
  }

  const validaColunaIp = useCallback((params) => {
    let errorMessage = null;

    errorMessage = !params?.value
      ? "Campo obrigatório"
      : validateIp(params?.value?.toString())
      ? null
      : "Formato de IP inválido";
    return errorMessage;
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 100, hide: true },
    {
      field: "interfaceid",
      headerName: "Interface ID",
      width: 100,
    },
    {
      field: "nome",
      headerName: "NOME",
      width: 200,
      editable: true,
      renderEditCell: (params) => {
        return (
          <EditcellTextField {...params} error={validaColunaNome(params)} />
        );
      },
    },

    {
      field: "ip",
      headerName: "IP",
      width: 150,
      editable: true,
      renderEditCell: (params) => {
        return <EditcellTextField {...params} error={validaColunaIp(params)} />;
      },
    },
    {
      field: "interface",
      headerName: "INTERFACE",
      width: 120,
      type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CellExibition {...params} error={validaColObrigatoria(params)} />
        );
      },
      renderEditCell: (params) => {
        const tipos = [{ interface: "AGENTE" }, { interface: "SNMP" }];

        return (
          <EditCellAutoCompleteInterface
            {...params}
            lista={tipos}
            error={validaColObrigatoria(params)}
            pre={false}
          />
        );
      },
    },
    {
      field: "tipoSnmp",
      headerName: "TIPO SNMP",
      width: 120,
      type: "",
      editable: true,
      renderCell: (params) => {
        let erro =
          params.row.interface == "SNMP"
            ? !tiposSNMP.some((a) => a.tipoSnmp == params.value)
            : false;
        return (
          <CellExibitionAlerts
            {...params}
            alert={erro}
            text={"nas opções disponíveis"}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <EditCellAutoComplete
            {...params}
            lista={tiposSNMP}
            error={validaColObrigatoria(params)}
            pre={false}
          />
        );
      },
    },
    {
      field: "comunidade",
      headerName: "COMUNIDADE",
      width: 160,
      editable: true,
      renderCell: (params) => {
        return <CellExibition {...params} />;
      },
      renderEditCell: (params) => {
        return <EditcellTextField {...params} />;
      },
    },
    {
      field: "portaSnmp",
      headerName: "PORTA SNMP",
      width: 110,
      editable: true,
      renderCell: (params) => {
        return (
          <CellExibition {...params} error={validaColObrigatoria(params)} />
        );
      },
      renderEditCell: (params) => {
        return (
          <EditcellTextField {...params} error={validaColObrigatoria(params)} />
        );
      },
    },

    {
      field: "fabricante",
      headerName: "FABRICANTE",
      editable: true,
      width: 150,
      renderCell: (params) => {
        let alert = !predefinicoes.some(
          (a) => a.fabricante?.toUpperCase() == params.value?.toUpperCase()
        );
        return (
          <CellExibitionAlerts
            value={params.value?.toUpperCase()}
            alert={alert}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <EditCellAutoComplete {...params} lista={predefinicoes} pre={false} />
        );
      },
    },
    {
      field: "funcao",
      headerName: "FUNÇÃO",
      width: 150,
      editable: true,
      renderCell: (params) => {
        const { Funcoes: funcoes = [] } =
          predefinicoes.find(
            ({ fabricante }) =>
              fabricante?.toUpperCase() == params.row?.fabricante?.toUpperCase()
          ) || {};
        let alert = !funcoes.some(
          (a) => a.funcao?.toUpperCase() == params.value?.toUpperCase()
        );
        return (
          <CellExibitionAlerts
            value={params.value?.toUpperCase()}
            alert={alert}
          />
        );
      },
      renderEditCell: (params) => {
        // Encontra as funções predefinidas para o fabricante específico e desestrutura para obter as funções ou um array vazio
        const { Funcoes: funcoes = [] } =
          predefinicoes.find(
            ({ fabricante }) =>
              fabricante?.toUpperCase() == params.row?.fabricante?.toUpperCase()
          ) || {};
        // Função para redefinir os parâmetros do host editado
        return <EditCellAutoComplete {...params} lista={funcoes} pre={false} />;
      },
    },
    {
      field: "modelo",
      headerName: "MODELO",
      width: 150,
      hide: true,
      editable: true,
      renderCell: (params) => {
        // Encontra as funções predefinidas para o fabricante específico e desestrutura para obter as funções ou um array vazio
        const { Funcoes: funcoes = [] } =
          predefinicoes?.find(
            ({ fabricante }) =>
              fabricante?.toUpperCase() ===
              params.row?.fabricante?.toUpperCase()
          ) || {};
        // Encontra os modelos predefinidos para a função específica e desestrutura para obter os modelos ou um array vazio
        const { Modelos: modelos = [] } =
          funcoes?.find(
            ({ funcao }) =>
              funcao?.toUpperCase() === params.row?.funcao?.toUpperCase()
          ) || {};

        let alert = !modelos.some(
          (a) => a.modelo?.toUpperCase() == params.value?.toUpperCase()
        );
        return (
          <CellExibitionAlerts
            value={params.value?.toUpperCase()}
            alert={alert}
          />
        );
      },
      renderEditCell: (params) => {
        // Encontra as funções predefinidas para o fabricante específico e desestrutura para obter as funções ou um array vazio
        const { Funcoes: funcoes = [] } =
          predefinicoes?.find(
            ({ fabricante }) =>
              fabricante?.toUpperCase() ===
              params.row?.fabricante?.toUpperCase()
          ) || {};
        // Encontra os modelos predefinidos para a função específica e desestrutura para obter os modelos ou um array vazio
        const { Modelos: modelos = [] } =
          funcoes?.find(
            ({ funcao }) =>
              funcao?.toUpperCase() === params.row?.funcao?.toUpperCase()
          ) || {};
        // Retorna o componente EditCellAutoComplete com a lista de modelos
        return <EditCellAutoComplete {...params} lista={modelos} pre={false} />;
      },
    },
    {
      field: "coletor",
      headerName: "COLETOR",

      width: 150,
      editable: true,
      renderCell: (params) => {
        let proxs = proxys.map((a) => ({ coletor: a.host }));
        let alert = !proxs.some((a) => a.coletor == params.value);
        //    let alert = !modelos.some(a => (a.modelo)?.toUpperCase() == (params.value)?.toUpperCase())
        return (
          <CellExibitionAlerts
            value={params.value?.toUpperCase()}
            alert={alert}
            text="no cliente"
          />
        );
      },

      renderEditCell: (params) => {
        let proxs = proxys.map((a) => ({ coletor: a.host }));
        return <EditCellAutoComplete {...params} lista={proxs} pre={false} />;
      },
    },
    {
      field: "ping",
      headerName: "PING",
      width: 70,
      editable: true,
      align: "center",
      renderCell: (params) => (
        <Tooltip
          title={
            params.value
              ? "Teste de ping retonou corretamente"
              : "Teste de ping falhou"
          }
        >
          {params.value ? (
            <CheckCircle color="success" />
          ) : (
            <Error color="error" />
          )}
        </Tooltip>
      ),
      renderEditCell: (params) => (
        <Stack
          sx={{
            opacity: 0.2,
          }}
        >
          <RemoveCircle color="disabled" />
        </Stack>
      ),
    },
    {
      field: "snmp",
      headerName: "SNMP",
      width: 65,
      type: "",
      align: "center",
      editable: true,
      renderCell: (params) => (
        <Tooltip
          title={
            params.value == true
              ? "Teste de snmp retonou corretamente"
              : params.value == false
              ? "Teste de snmp falhou"
              : "Teste desabilitado"
          }
        >
          {params.value === true ? (
            <Chip>
              {" "}
              <CheckCircle color="success" />
            </Chip>
          ) : params.value === false ? (
            <Error color="error" />
          ) : (
            <RemoveCircle color="disabled" />
          )}
        </Tooltip>
      ),
      renderEditCell: (params) => (
        <Stack
          sx={{
            opacity: 0.2,
          }}
        >
          <RemoveCircle color="disabled" />
        </Stack>
      ),
    },
    {
      field: "userSsh",
      headerName: "USER SSH",

      width: 100,
      editable: true,
    },
    {
      field: "portaSsh",
      headerName: "PORTA SSH",
      width: 100,
      editable: true,
    },
    {
      field: "senhaSsh",
      headerName: "SENHA SSH",
      width: 100,
      editable: true,
    },
    {
      field: "userTelnet",
      headerName: "USER TELNET",

      width: 100,
      editable: true,
    },
    {
      field: "portaTelnet",
      headerName: "PORTA TELNET",
      width: 110,
      editable: true,
    },
    {
      field: "senhaTelnet",
      headerName: "SENHA TELNET",
      width: 110,
      editable: true,
    },
    {
      field: "actions",
      align: "center",
      headerAlign: "center",
      sortable: false,
      editable: true,
      headerName: "OPÇÕES",
      sortable: false,
      width: 100,

      renderCell: Actions,
      renderEditCell: ActionsEdit,
    },
  ];

  async function handleApiEditHost(host) {
    setLoading(true);
    {
      try {
        const response = await api.put("/zabbixPutHosts", {
          hostId: host.id,
          nome: host.nome,
          fabricante: host.fabricante,
          funcao: host.funcao,
          modelo: host.modelo,
          userSsh: host.userSsh,
          portaSsh: host.portaSsh,
          senhaSsh: host.senhaSsh,
          userTelnet: host.userTelnet,
          portaTelnet: host.portaTelnet,
          senhaTelnet: host.senhaTelnet,
          coletor: host.coletor,
          interfaces: [
            {
              interface: host.interface,
              portaSnmp: host.portaSnmp,
              comunidade: host.comunidade,
              ip: host.ip,
              tipoSnmp: host.tipoSnmp,
            },
          ],
          acessoClientesId,
          acessoProdutoId,
        });

        if (response.data.status === "Error") {
          console.error(response.data);
          setSnackbar({
            children: "Error: Não foi possível salvar as alterações",
            severity: "error",
          });
        } else {
          setRows((data) => {
            return [
              ...data.map((row) =>
                row.id == host.id ? { ...row, ...host } : row
              ),
            ];
          });
          setSnackbar({
            children: "Dados salvos com sucesso",
            severity: "success",
          });
        }
      } catch (error) {
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });

        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  const processRowUpdate = useCallback(async (newRow, oldRow) => {
    handleApiEditHost(newRow);
    return newRow;
  }, []);

  return (
    <Box sx={styles.container}>
      <Stack
        direction={"column"}
        gap="20px"
        sx={{
          width: "100%",
        }}
      >
        <Stack
          direction={"row"}
          gap={"20px"}
          width={"100%"}
          //minWidth={"650px"}
          sx={{ flexWrap: "wrap" }}
        >
          <TextField
            id="host-nome"
            variant="standard"
            label="Nome"
            value={nome}
            onChange={(event) => {
              setNome(event.target.value);
            }}
            type="text"
          />
          <TextField
            id="ip"
            variant="standard"
            label="IP"
            value={ip}
            onChange={handleChangeIp}
            onBlur={() => setError(validateIp(ip))}
            error={Boolean(error)}
            helperText={error}
          />

          <Autocomplete
            inputValue={fabricante}
            onInputChange={(event, newInputValue) => {
              setFabricante(newInputValue);
            }}
            freeSolo
            options={fabricantes.map((options) => options.fabricante)}
            sx={styles.filter}
            renderInput={(params) => (
              <TextField {...params} label="Fabricante" variant="standard" />
            )}
          />
          <TextField
            id="host-funcao"
            variant="standard"
            label="Função"
            type="text"
            value={funcao}
            onChange={(event) => {
              setFuncao(event.target.value);
            }}
          />
          <FormControl variant="standard" label="Coletor">
            <InputLabel> Coletor</InputLabel>
            <Select
              label="Coletor"
              //fullWidth
              value={coletor}
              onChange={(event) => {
                setColetor(event.target.value);
              }}
              sx={styles.filter}
            >
              <MenuItem value={""}>TODOS</MenuItem>
              <MenuItem value={"ZABBIX SERVER"}>ZABBIX SERVER</MenuItem>
              <MenuItem value={"PROXY"}>PROXY</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack
          sx={{
            width: "100%",

            height:
              filteredRows.length > 19
                ? "800px"
                : filteredRows.length == 0
                ? "250px"
                : filteredRows.length * 36 + 115 + "px",
          }}
        >
          <DataGrid
            //getRowHeight={() => "auto"}
            experimentalFeatures={{ newEditingApi: true }}
            key={"dataGridZabbix"}
            density="compact"
            editMode="row"
            columns={columns}
            onRowEditStop={(params, event) => {
              event.defaultMuiPrevented = true;
              event.stopPropagation();
            }}
            onCellDoubleClick={(params, event) => {
              event.defaultMuiPrevented = true;
              event.stopPropagation();
            }}
            onRowDoubleClick={(params, event) => {
              event.defaultMuiPrevented = true;
              event.stopPropagation();
            }}
            processRowUpdate={processRowUpdate}
            // onRowEditStop={(params, event) => {
            //     if (
            //         /*     params.reason === GridRowEditStopReasons.rowFocusOut || */
            //         params.reason === "escapeKeyDown" ||
            //         params.reason === "enterKeyDown"
            //     ) {
            //         event.defaultMuiPrevented = true;
            //     }
            // }}

            rows={filteredRows}
            // autoHeight={true}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            pageSizeOptions={[25, 50, 100]}
            sx={styles.dataGrid}
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            //page={1}
            loading={loading}
            // components={{ Toolbar: GridToolbar }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            disableColumnMenu
            disableColumnFilter
            disableSelectionOnClick
          />
        </Stack>
      </Stack>

      <AlertModal
        openModal={openDeleteDialog.open}
        setOpenModal={setOpenDeleteDialog}
        confirmar={disableButton}
        handleButton={() => handleApiDelete(openDeleteDialog.id)}
        severity={"error"}
        singleButton={false}
      />
      <AlertModal
        openModal={openEditDialog}
        setOpenModal={setOpenEditDialog}
        confirmar={disableButtonDelete}
        //handleButton={handleApiDelete}
        severity={"warning"}
        singleButton={false}
      />

      <CopyModal
        openCopyModal={openCopyModal}
        setOpenCopyModal={setOpenCopyModal}
      />

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
