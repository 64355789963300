import { createTheme } from "@mui/material/styles";

const mainTheme = (colorMode) =>
  createTheme({
    palette: {
      mode: !colorMode ? "light" : "dark",
      primary: {
        main: !colorMode ? "#5B2E87" : "#A77AD2",
      },
      secondary: {
        main: "#e83750",
      },
      drawer: {
        chat: !colorMode ? "#fff " : "#000000",
      },
      background: {
        default: !colorMode ? "#F0F2F5" : "#141617",
        paper: !colorMode ? "#fff " : "#121212",
        paperChat: !colorMode ? "#F9FAFB" : "#353535",
        paperOutlined: !colorMode ? "#fff " : "#252525",
        paperNotification: !colorMode ? "#f1f1f1" : "#1d2021",
        preSelectedTreeview: !colorMode ? "#f1f1f1" : "#303030",
      },
      rightBar: {
        preSelected: !colorMode ? "#f1f1f1" : "#313131",
        selected: !colorMode ? "#EBE6F0" : "#2d2833",
        hoverSelected: !colorMode ? "#d8cde2" : "#3F3449",
        textDisabled: !colorMode ? "#3E4041" : "#BCBCBC",
        text: !colorMode ? "#141414" : "#fff",
      },
      chip: {
        pending: !colorMode ? "#E83750" : "#A61328",
        inProgress: !colorMode ? "#ED6C02" : "#BE5602",
        completed: !colorMode ? "#2E7D32" : "#387F4D",
        waitingReview: !colorMode ? "#0288D1" : "#077CB1",
        waitingReturn: !colorMode ? "#5B2E87" : "#502876",
        arquive: !colorMode ? "#777777" : "#424242",
      },
      iconsAcesso: {
        proritario: !colorMode ? "#0288D1" : "#077CB1",
        exigente: !colorMode ? "#E83750" : "#A61328",
        customizado: !colorMode ? "#ED6C02" : "#BE5602",
      },
      color: {
        textDisable: !colorMode ? "rgb(112, 110, 110)" : "rgb(112, 110, 110)",
      },
      text: {
        primary: !colorMode ? "#3c3c3c" : "#ececec",
        secondary: !colorMode ? "#757575" : "#9a9a9a",
        disabled: !colorMode ? "#9e9e9e" : "#878686",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        smd: 800,
        md: 900,
        lg: 1200,
        xl: 1536,
        fh: 1920,
        qhd: 2560,
      },
    },
    components: colorMode && {
      MuiButton: {
        styleOverrides: {
          containedPrimary: { backgroundColor: "#5B2E87" },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: { backgroundColor: "#000000" },
        },
      },
      MuiTextField: {
        variant: "standard",
      },
    },
    mixins: {
      MuiDataGrid: {
        containerBackground: !colorMode ? "#fff" : "#1E1E1E",
      },
    },
  });

export default mainTheme;
