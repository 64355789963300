import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Paper,
  Stack,
  Button,
  IconButton,
  Snackbar,
  Drawer,
  Typography,
  Divider,
  Skeleton,
  Tooltip,
  LinearProgress,
  Alert,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Resizer from "react-image-file-resizer";
import Editor from "../../../../../componentes/editorChat";

import api from "../../../../../services/api";
import CardComentario from "./cardComentario";

const stylesChat = {
  containerChat: {
    width: "800px",
    padding: "30px",
    gap: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  paperChat: {
    gap: "15px",
    display: "flex",
    flexDirection: "row",
    borderRadius: 5,
    elevation: 0,
    bgcolor: "background.paperChat",
    alignItems: "start",
    //  margin: "20px",
    padding: "10px",
  },
  boxComment: {
    gap: "20px",
    display: "flex",
    flexDirection: "row",
    borderRadius: 3,
    elevation: 0,
    alignItems: "center",
    bgcolor: "background.paperChat",
    alignItems: "start",
  },
  boxResponse: {
    //mt: 1,
    gap: "20px",
    display: "flex",
    flexDirection: "row",
    borderRadius: 3,
    elevation: 0,
    alignItems: "center",
    bgcolor: "background.paperChat",
    alignItems: "start",
  },
  fieldComment: {
    width: "470px",
    ml: "100px",
  },
  avatarComment: { width: "50px", height: "50px", marginLeft: "10px" },
  avatarResponse: { width: "30px", height: "30px", ml: 6 },
  buttonShowMore: {
    fontSize: "0.8rem",
    height: "1.6rem",
    width: "9rem",
    margin: "auto",
  },
};

export default function Chat(props) {
  let { chatId } = useParams();
  const { selectedRow, atualizaBadgeTopicos, socket, CommentIcon } = props;
  const [snackbar, setSnackbar] = useState(false);
  const [novoComentario, setNovoComentario] = useState("");
  const [descricao, setDescricao] = useState("");
  const [openCreateInput, setOpenCreateInput] = useState(false);
  const [imagens, setImagens] = useState([]);
  const [comentarios, setComentarios] = useState(null);
  const [loadingChat, setLoadingChat] = useState(false);
  const [disabledButtonTopic, setDisabledButtonTopic] = useState(true);
  const [usuarioId, setUsuarioId] = useState("");
  const [getNewComment, setGetNewComment] = useState("");
  const [fotoUsuario, setFotoUsuario] = useState("");
  const [nomeUsuario, setNomeUsuario] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    function topicEvent(data) {
      setGetNewComment(data.dados);
    }
    socket.on(`createTopicoRelatorio`, topicEvent);
    return () => {
      socket.off(`createTopicoRelatorio`, topicEvent);
    };
  }, [socket]);
  useEffect(() => {
    if (getNewComment && chatId === getNewComment.relatoriosClienteId) {
      createComentario(formataComentario(getNewComment));
      atualizaBadgeTopicos(1);
    }
  }, [getNewComment]);
  const handleClose = () => {
    clearDrawer();
    navigate("/noc/clientes/suporte");
  };
  useEffect(() => {
    if (chatId) {
      handleApiGetTopicos();
    }
  }, [chatId]);
  useEffect(() => {
    let usuario = localStorage.getItem("1");
    if (usuario !== "undefined" && usuario) {
      usuario = JSON.parse(usuario);
      setUsuarioId(usuario.refreshToken.usuarioId);
    }
    let perfil = localStorage.getItem("2");
    if (perfil !== "undefined" && perfil) {
      perfil = JSON.parse(perfil);
      setFotoUsuario(perfil.foto);
      setNomeUsuario(perfil.nome || perfil.nomeFantasia);
    }
  }, []);
  function clearDrawer() {
    setNovoComentario("");
    setOpenCreateInput(false);
    setImagens([]);
  }
  function formataArrayComentarios(params) {
    const pushTopics = params.map((topic, index) => {
      let obj = {};
      obj.id = topic.id;
      obj.fixar = topic.fixar;
      obj.imagens = topic.MidiaChat;
      obj.countRespostas = topic._count.ChatTopico;
      obj.data = new Date(topic.createAt);
      obj.dataUpdate = topic.updateAt ? new Date(topic.updateAt) : null;
      obj.usuarioId = topic.usuarioId;
      obj.usuarioClienteId = topic.usuarioClienteId;
      obj.usuario = topic.usuario;
      obj.usuarioCliente = topic.usuarioCliente;
      obj.texto = topic.texto;
      obj.respostas = topic.ChatTopico;
      return obj;
    });
    setComentarios(pushTopics);
  }
  function formataComentario(topic) {
    let obj = {};
    obj.id = topic.id;
    obj.fixar = topic.fixar;
    obj.imagens = topic.MidiaChat;
    obj.countRespostas = topic._count?.ChatTopico ? topic._count.ChatTopico : 0;
    obj.data = new Date(topic.createAt);
    obj.dataUpdate = topic.updateAt ? new Date(topic.updateAt) : null;
    obj.usuarioId = topic.usuarioId;
    obj.usuarioClienteId = topic.usuarioClienteId;
    obj.usuario = topic.usuario;
    obj.usuarioCliente = topic.usuarioCliente;
    obj.texto = topic.texto;
    obj.respostas = obj.respostas ? obj.respostas : [];
    return obj;
  }
  function handleTime(params) {
    const agora = new Date();
    const segundos = parseInt((Date.parse(agora) - Date.parse(params)) / 1000);
    const horas = Math.floor(segundos / (60 * 60));
    const dias = horas > 24 && Math.floor(horas / 24);
    const minutos = Math.floor(segundos / 60) % 60;
    if (dias > 0) {
      if (dias <= 1) {
        return parseInt(dias) + " dia atrás";
      }
      return parseInt(dias) + " dias atrás";
    } else if (horas > 0) {
      if (horas <= 1) {
        return parseInt(horas) + " h atrás";
      } else {
        return parseInt(horas) + " hrs atrás";
      }
    } else if (minutos > 0) {
      return parseInt(minutos) + " min atrás";
    } else {
      return "segs atrás";
    }
  }
  function updateComentario(newComment) {
    const novoArray = comentarios.map((comentario) => {
      if (comentario.id === newComment.id) {
        let auxComentario = newComment;
        return { ...auxComentario };
      } else {
        return comentario;
      }
    });
    setComentarios(novoArray);
  }
  function createComentario(newComment) {
    const indexToInsert = comentarios.findIndex(
      (comentario) => !comentario.fixar
    );
    if (indexToInsert !== -1) {
      const novoArray = [...comentarios];
      novoArray.splice(indexToInsert, 0, newComment);
      setComentarios(novoArray);
    } else {
      setComentarios([newComment, ...comentarios]);
    }
  }
  function fixarComentario(comentarioId, fixar) {
    const fixedArray = comentarios.map((comentario) => {
      if (comentario.id === comentarioId) {
        let auxComentario = comentario;
        auxComentario.fixar = fixar;
        return auxComentario;
      } else {
        return comentario;
      }
    });
    fixedArray.sort(function (a, b) {
      if (a.fixar > b.fixar) {
        return -1;
      }
      if (a.fixar < b.fixar) {
        return 1;
      }
      //se os dois sao iguais (fixados ou nao) compara a data
      if (a.data > b.data) {
        return -1;
      }
      if (a.data < b.data) {
        return 1;
      }
      return 0;
    });
    setComentarios(fixedArray);
  }
  function deleteComentario(deletedComment) {
    const novoArray = comentarios.filter(
      (comentario) => comentario.id !== deletedComment.id
    );
    setComentarios(novoArray);
  }
  async function handleApiGetTopicos() {
    try {
      setLoadingChat(true);
      const response = await api.post("/suporte/topico/findMany", {
        relatoriosClienteId: chatId,
      });

      if (response.data.status === "Error") {
        setLoadingChat(false);
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
      } else {
        setLoadingChat(false);
        if (response.data) {
          formataArrayComentarios(response.data.topicoRelatorio);
          setDescricao(response.data.relatoriosCliente.descricao);
        }
      }
    } catch (error) {
      setLoadingChat(false);
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }

  async function handleApiPostTopico() {
    try {
      // setLoadingChat(true);

      const response = await api.post("/suporte/topico/create", {
        texto: novoComentario,
        relatoriosClienteId: chatId,
        midia: Array.isArray(imagens) ? imagens : null,
        logsLoginId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.data.status === "Error") {
        //  setLoadingChat(false);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        // setLoadingChat(false);
        if (response.data) {
          //createComentario(formataComentario(response.data));
          //atualizaBadgeTopicos(1);
        }
        clearDrawer();
      }
    } catch (error) {
      // setLoadingChat(false);
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }

  async function processarFotos(files) {
    const imagens = [];

    for (let i = 0; i < files.length; i++) {
      const uri = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          files[i],
          525,
          350,
          "JPEG",
          80,
          0,
          (resizedUri) => {
            resolve(resizedUri);
          },
          "base64",
          350,
          350
        );
      });

      imagens.push({ foto: uri, nome: files[i].name });
    }
    setImagens((imagensAntigas) => [...imagensAntigas, ...imagens]);
  }
  const removerImagem = (indexToRemove) => {
    const novaListaDeFotos = [...imagens];
    novaListaDeFotos.splice(indexToRemove, 1);
    setImagens(novaListaDeFotos);
  };

  return (
    <Drawer
      anchor="right"
      open={Boolean(chatId)}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: "drawer.chat",
        },
      }}
    >
      <>
        <Stack sx={stylesChat.containerChat}>
          <IconButton sx={{ position: "absolute" }} onClick={handleClose}>
            <Close color="action" />
          </IconButton>{" "}
          <Typography
            variant="h6"
            component={"div"}
            gutterBottom
            sx={{
              fontSize: "1.5rem",
              fontWeight: "500",
              ml: 5,
            }}
            color="primary"
          >
            {descricao}
          </Typography>
          <Box>
            {!openCreateInput && (
              <Button
                sx={{ width: "100%" }}
                onClick={() => setOpenCreateInput(true)}
                variant="outlined"
              >
                Adicionar um comentário
              </Button>
            )}
            {openCreateInput && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "40px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Editor
                    style={{ width: "100%" }}
                    value={novoComentario}
                    onChange={(value) => {
                      setNovoComentario(value);
                      if (!value || value === "<p><br></p>") {
                        setDisabledButtonTopic(true);
                      } else {
                        setDisabledButtonTopic(false);
                      }
                    }}
                  />
                  <Tooltip title="Adicionar Imagem">
                    <IconButton component="label" sx={{ ml: 1 }}>
                      <AttachFileIcon />
                      <input
                        hidden
                        multiple
                        accept=".png, .jpg, .jpeg"
                        type="file"
                        onChange={(e) => {
                          const files = e.target.files;
                          processarFotos(files);
                        }}
                      />{" "}
                    </IconButton>
                  </Tooltip>
                </Box>
                {imagens?.map((img, index) => (
                  <Box sx={{ width: "85%", ml: 1, mt: 1 }}>
                    <Typography sx={{ fontSize: "0.75rem" }}>
                      {img.nome}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: "-17px",
                        mb: "-17px",
                      }}
                    >
                      <LinearProgress
                        sx={{ width: "100%" }}
                        variant="determinate"
                        value={100}
                      />
                      <IconButton onClick={() => removerImagem(index)}>
                        <Close sx={{ width: "20px" }} />
                      </IconButton>
                    </Box>
                    <Typography sx={{ fontSize: "0.75rem" }}>
                      Completo
                    </Typography>
                  </Box>
                ))}
                <Box
                  sx={{ width: "83%", display: "flex", justifyContent: "end" }}
                >
                  <Button
                    onClick={() => {
                      setOpenCreateInput(false);
                      clearDrawer();
                    }}
                    color="secondary"
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={disabledButtonTopic}
                    onClick={() => {
                      handleApiPostTopico();
                    }}
                  >
                    Comentar
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <Divider></Divider>
          {loadingChat ? (
            <Paper sx={stylesChat.paperChat} variant="outlined">
              <Skeleton sx={stylesChat.avatarComment} variant="circular" />
              <Stack direction={"column"} width="85%">
                {" "}
                <Skeleton
                  animation="wave"
                  height={20}
                  width="30%"
                  style={{ marginBottom: 17 }}
                />
                <Skeleton
                  animation="wave"
                  height={20}
                  style={{ marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  height={20}
                  style={{ marginBottom: 6 }}
                />
                <Skeleton animation="wave" height={20} width="80%" />
              </Stack>
            </Paper>
          ) : Array.isArray(comentarios) && comentarios.length > 0 ? (
            comentarios.map((comentario, index) => (
              <CardComentario
                key={comentario.id}
                comentario={comentario}
                usuarioId={usuarioId}
                removerImagem={removerImagem}
                formataComentario={formataComentario}
                fotoUsuario={fotoUsuario}
                nomeUsuario={nomeUsuario}
                updateComentario={updateComentario}
                deleteComentario={deleteComentario}
                atualizaBadgeTopicos={atualizaBadgeTopicos}
                fixarComentario={fixarComentario}
                setSnackbar={setSnackbar}
                socket={socket}
                CommentIcon={CommentIcon}
              />
            ))
          ) : (
            <Typography>Nenhum comentário encontrado</Typography>
          )}
        </Stack>
      </>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
    </Drawer>
  );
}
