import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  Icon,
  IconButton,
  InputAdornment,
  Button,
  Snackbar,
  Alert,
  Avatar,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Visibility, VisibilityOff } from "@mui/icons-material";

//

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import logo from "../../assets/logo3.png";
import { Navigate, useNavigate } from "react-router-dom";
import api from "../../services/api";

import Resizer from "react-image-file-resizer";

export default function PrimeiroAcesso(params) {
  const [showPass, setShowPass] = useState(false);
  const [showPassConf, setShowPassConf] = useState(false);
  const [data, setData] = useState(dayjs(new Date()));
  const [novaSenha, setNovaSenha] = useState("");
  const [confSenha, setConfSenha] = useState("");
  const [checkSenha, setCheckSenha] = useState(true);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [checkCadastro, setCheckCadastro] = useState(true);
  const [alertSize, setAlertSize] = useState(false);
  const [baseImage, setBaseImage] = useState("");
  const [sector, setSector] = useState("");
  const [email, setEmail] = useState("");
  const [usuarioId, setUsuarioId] = useState("");

  const [snackbar, setSnackbar] = useState(null);

  const sectorChange = (event) => {
    setSector(event.target.value);
  };
  useEffect(() => {
    if (confSenha === novaSenha) {
      setCheckSenha(false);
    } else {
      setCheckSenha(true);
    }
  }, [novaSenha, confSenha]);

  useEffect(() => {
    if (
      confSenha === novaSenha &&
      novaSenha !== "" &&
      confSenha !== "" &&
      name !== "" &&
      lastName !== "" &&
      sector !== "" &&
      email !== ""
    ) {
      setCheckCadastro(false);
    } else {
      setCheckCadastro(true);
    }
  }, [novaSenha, name, lastName, confSenha, sector, email]);

  function checkPerfil() {
    let perfilAlreadyExists =
      JSON.parse(localStorage.getItem("1"))?.perfilAlreadyExists || false;
    let satusPerfil = JSON.parse(localStorage.getItem("2"))?.status || false;
    if (perfilAlreadyExists === true && satusPerfil === true) {
      navigate("/noc");
    }
  }

  useEffect(() => {
    let aux = handleUsuarioId();
    setUsuarioId(aux);
    checkPerfil();
  }, []);

  const navigate = useNavigate();

  const handleVoltar = () => {
    localStorage.clear();
    navigate("/login");
  };
  const styles = {
    ContainerBox: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    PurpleBox: {
      height: "90px",
      width: "100%",
      paddingTop: "5px",
      backgroundColor: "#5B2E87",
      color: "#ffffff",
    },
    HeaderBox: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "30px  0px",
    },
    FooterBox: {
      alignItems: "flex-end",
      width: "100%",
      height: "100%",
      display: "flex",
    },
    TitleForm: {
      xs: 30,
      sm: 20,
    },
    PaperForm: {
      padding: "40px 20px",
      width: "40vh",
    },
    TextCampo: {
      mt: 1,
      mb: 1,
      width: "220px",
    },
  };

  async function handleApi() {
    try {
      const perfil = JSON.parse(localStorage.getItem("2"));

      let response;
      if (perfil) {
        response = await api.put("/updatePerfil", {
          nome: name,
          sobrenome: lastName,
          email: email,
          dataDeNascimento: data,
          foto: baseImage,
          status: true,
          setor: sector,
          usuarioId,
          novaSenha,
        });
      } else {
        response = await api.post("/novoPerfil", {
          nome: name,
          sobrenome: lastName,
          email: email,
          dataDeNascimento: data,
          foto: baseImage,
          status: true,
          setor: sector,
          usuarioId,
          novaSenha,
        });
      }

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });

        localStorage.setItem("2", JSON.stringify(response.data.perfil));

        navigate("/noc");
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  //console.log(JSON.parse(localStorage.getItem("1")).perfil.status);

  const handleUsuarioId = () => {
    let result = localStorage.getItem("1");
    result = JSON.parse(result);
    if (result) {
      return result.refreshToken.usuarioId;
    } else {
      return "";
    }
  };

  handleUsuarioId();

  return (
    <>
      <Box id="container" sx={styles.ContainerBox}>
        <Box sx={{ height: "80%" }}>
          <Box id="purpleBox" sx={styles.PurpleBox} />
        </Box>
        <Box id="headerBox" sx={styles.HeaderBox}>
          <Paper sx={styles.PaperForm}>
            <Icon style={{ height: "auto", width: "auto" }}>
              <img
                alt=""
                src={logo}
                style={{ height: "auto", width: "280px" }}
              />
            </Icon>
            <Stack
              direction={"column"}
              gap={"15px"}
              justifyContent={"center"}
              alignItems={"center"}
              color={"#5B2E87"}
            >
              <Typography
                variant="h1"
                fontSize={{ xs: 30, sm: 20 }}
                fontWeight="500"
              >
                Primeiro Acesso
              </Typography>
              <TextField
                sx={styles.TextCampo}
                label="Nome"
                id="nome"
                name="nome"
                variant="standard"
                color="primary"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
              <TextField
                sx={styles.TextCampo}
                label="Sobrenome"
                id="sobrenome"
                name="sobrenome"
                variant="standard"
                color="primary"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
              <TextField
                sx={styles.TextCampo}
                label="Email"
                id="email"
                name="email"
                variant="standard"
                color="primary"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt-br"}
              >
                <DatePicker
                  id="dataDeNascimento"
                  label="Data de Nascimento"
                  sx={styles.TextCampo}
                  color="primary"
                  value={data}
                  onChange={(newValeu) => {
                    setData(newValeu);
                  }}
                  slotProps={{
                    textField: {
                      variant: "standard",
                    },
                  }}
                />
              </LocalizationProvider>
              <FormControl variant="standard" sx={styles.TextCampo}>
                <InputLabel id="demo-simple-select-label">Setor</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={sector}
                  onChange={sectorChange}
                  name="setor"
                  color="primary"
                  //inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={"NOC"}>NOC</MenuItem>
                  <MenuItem value={"DEV"}>DEV</MenuItem>
                  <MenuItem value={"IOT"}>IOT</MenuItem>
                  <MenuItem value={"FINANCEIRO"}>FINANCEIRO</MenuItem>
                  <MenuItem value={"VENDARKETING"}>VENDARKETING</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={styles.TextCampo}
                name="senha"
                label="Nova Senha"
                variant="standard"
                color="primary"
                type={showPass ? "text" : "password"}
                value={novaSenha}
                onChange={(event) => {
                  setNovaSenha(event.target.value);
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPass((event) => !event)}
                        >
                          {showPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />

              <TextField
                error={checkSenha}
                sx={styles.TextCampo}
                name="confirmarsenha"
                label="Confirmar nova senha"
                variant="standard"
                color="primary"
                helperText={checkSenha && "Essas senhas não correspondem"}
                type={showPassConf ? "text" : "password"}
                value={confSenha}
                onChange={(event) => {
                  setConfSenha(event.target.value);
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassConf((event) => !event)}
                        >
                          {showPassConf ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />

              {/* {profileImg ? (
                <img
                  src={baseImage}
                  alt="profileimage"
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                  }}
                />
              ) : (  )}*/}
              <Avatar
                src={baseImage}
                sx={{
                  width: "80px",
                  height: "80px",
                }}
              />

              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                direction="row"
                spacing={2}
              >
                <Typography variant="h5" fontSize={{ xs: 20, sm: 15 }}>
                  Adicionar foto de perfil
                </Typography>

                <IconButton
                  sx={{
                    backgroundColor: "#D1CBD5",
                    mt: 1,
                    mb: 1,
                  }}
                  variant="standard"
                  component="label"
                >
                  <input
                    hidden
                    accept=".png, .jpg, .jpeg"
                    type="file"
                    onChange={(e) => {
                      //  setDialogFoto(true);
                      //  if (salvarFoto) {
                      const file = e.target.files[0];
                      if (file.size < 1024 * 4000) {
                        //1024 byte = 1 kbyte
                        // 1 MB
                        Resizer.imageFileResizer(
                          file,
                          500,
                          500,
                          "JPEG",
                          80,
                          0,
                          (uri) => {
                            setBaseImage(uri);
                            // Do something with the compressed image URI
                          },
                          "base64",
                          500,
                          500
                        );
                      } else {
                        setAlertSize(true);
                      }
                    }}
                  />
                  <PhotoCamera color="primary" />
                </IconButton>
              </Stack>

              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                direction="row"
                spacing={2}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleVoltar();
                  }}
                >
                  <ArrowBackRoundedIcon fontSize="medium" />
                  Voltar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={checkCadastro}
                  onClick={() => {
                    handleApi();
                  }}
                >
                  Cadastrar
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Box>
        <Box id="footerBox" sx={styles.FooterBox}>
          <Box id="purpleBoxFooter" sx={styles.PurpleBox}></Box>
        </Box>
      </Box>
      <Snackbar
        autoHideDuration={3000}
        open={alertSize}
        onClose={() => {
          setAlertSize(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Tamanho máximo permitido: 4MB</Alert>
      </Snackbar>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
