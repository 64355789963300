import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import cebacario from "../../../../../assets/cabecario.png";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Times-Roman",
    padding: 40,
  },
  section: {
    fontFamily: "Times-Roman",
    marginBottom: 10,
  },
  title: {
    fontFamily: "Times-Roman",
    fontSize: 16,
    marginBottom: 10,
  },

  text: {
    fontFamily: "Times-Roman",
    fontSize: 12,
    marginBottom: 5,
  },
  subtitle: {
    fontFamily: "Times-Roman",
    fontSize: 10,
    marginBottom: 5,
  },
  table: {
    fontFamily: "Times-Roman",
    display: "table",
    width: "auto",
    marginBottom: 10,
  },
  tableRow: {
    fontFamily: "Times-Roman",
    flexDirection: "row",
  },
  tableCell: {
    fontFamily: "Times-Roman",
    width: "25%",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
  },
  //image: { width: 600 },
  divider: {
    marginTop: "10px",
    borderTop: "2px dashed #bbb",
    marginBottom: "20px",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 40,
    right: 0,
    color: "grey",
  },
  footerText: {
    fontFamily: "Times-Roman",
    fontSize: 10,
  },
  tabelaTitle: { fontFamily: "Times-Bold", fontSize: 12 },
});

const DisponibilidadeInterfacePDF95th = ({ file }) => {
  const relatorio = file;

  if (relatorio) {
    return (
      <Document>
        <Page size="A4">
          <Image src={cebacario} style={styles.image} />

          <View style={styles.page}>
            <View style={styles.section}>
              <Text style={styles.title}>
                Relatório de tráfego: {relatorio.nomeDoRelatorio}
              </Text>

              <View style={styles.divider} />

              <Text style={styles.text}>
                Período do relatório: {relatorio.dataInicial} à{" "}
                {relatorio.dataFinal}
              </Text>
              <Text style={styles.text}>
                Tempo de inoperância: {relatorio.input_indisponibility_time}
              </Text>
              <Text style={styles.text}>
                Taxa de disponibilidade: {relatorio.input_disponibility}%
              </Text>
              <Text style={styles.text}>
                Nome do host: {relatorio.host_name}
              </Text>
              <Text style={styles.text}>
                Nome da Interface: {relatorio.interface_name}
              </Text>
              <Text style={styles.text}>
                Tráfego médio 95th percentil :{" "}
                {relatorio.interface_95th_percentil}
              </Text>
            </View>

            <View style={styles.divider} />

            <View style={styles.section}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text style={styles.tabelaTitle}>Tabela de Tráfego</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}>Tráfego total</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}>Tráfego médio</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}> 95th percentil</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}>Tráfego de Entrada</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}>
                      {relatorio.input_traffic_total}
                    </Text>
                  </View>

                  <View style={styles.tableCell}>
                    <Text style={styles.text}>
                      {relatorio.input_traffic_mean_bps}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}>
                      {relatorio.input_95th_percentil}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}>Tráfego de Saída</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}>
                      {relatorio.output_traffic_total}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}>
                      {relatorio.output_traffic_mean_bps}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}>
                      {relatorio.output_95th_percentil}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}>Tráfego Total</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}>
                      {relatorio.interface_traffic_total}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}>
                      {relatorio.interface_traffic_mean_bps}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.text}>
                      {relatorio.interface_95th_percentil}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.footer}>
            <Text style={styles.footerText}>
              Rua Porto das Oficinas, 8960 - Natal/RN
            </Text>
            <Text style={styles.footerText}>
              E-email: contato@mw-solucoes.com
            </Text>
            <Text style={styles.footerText}>Fone: (84) 9 9400-9454</Text>
          </View>
        </Page>
      </Document>
    );
  }
};

export default DisponibilidadeInterfacePDF95th;
