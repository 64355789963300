import { useState } from "react";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import {
  Typography,
  Box,
  IconButton,
  Stack,
  Chip,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";
import { ptBR } from "@mui/x-data-grid/locales";
import { CustomPagination } from "../../../../../componentes/easyAdd/customPagination";

export default function TabelaArquivados(props) {
  const theme = useTheme();
  const { hosts = [], onChangePreCadastro = () => {}, loading } = props;
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    senhaTelnet: false,
    portaTelnet: false,
    userTelnet: false,
    tipoSnmp: false,
  });
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    button: { height: "40px", minWidth: "120px", mt: 3, mb: 1 },
    filter: { width: "180px" },
    deleButton: { width: "100px", fontWeight: "800" },
    dangerousIcon: { fontSize: 60 },
    boxDialogs: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
    },
    dialogText: { color: "#D32F2F" },
    dataGrid: {
      width: "100%",
      fontSize: 13,

      ".MuiDataGrid-cell": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      fontSize: 13,
      "& .MuiDataGrid-selectedRowCount": {
        display: "none!important",
      },
      "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
      },
      "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
        outline: "none !important",
      },
    },
  };
  function updateStateHost(ids) {
    let hostsUpdate = hosts
      .filter((host) => ids.includes(host.id))
      .map((host) => ({ ...host, status: 0 }));
    if (hostsUpdate.length == 0) return;
    onChangePreCadastro(hostsUpdate);
  }

  function CellExibition({ id, field, value }) {
    return (
      <>
        <Typography sx={{ fontSize: "13px", width: "100%" }}>
          {value}
        </Typography>
      </>
    );
  }

  const columns = [
    { field: "id", headerName: "ID", width: 180, hide: true },
    {
      field: "updateAt",
      headerName: "TEMPO RESTANTE",
      headerAlign: "center",
      width: 140,
      editable: false,
      renderCell: (params) => {
        const lastUpdated = new Date(params.value).getTime();
        const now = Date.now();
        const interval =
          30 - parseInt((now - lastUpdated) / (60 * 60 * 24 * 1000));
        const bg =
          interval < 5
            ? theme.palette.chip.pending
            : interval < 15
            ? theme.palette.chip.inProgress
            : theme.palette.chip.warning;
        return (
          <Chip
            sx={{
              color: "white",
              width: "100px",
              height: "25px",
              backgroundColor: bg,
            }}
            label={interval + " dias"}
          />
        );
      },
    },
    {
      field: "nome",
      headerName: "NOME",
      width: 200,
      //width: 175,
      editable: false,
      renderCell: CellExibition,
    },
    {
      field: "ip",
      headerName: "IP",
      width: 200,
      editable: false,
      renderCell: CellExibition,
    },

    {
      field: "comunidade",
      headerName: "COMUNIDADE",
      width: 150,
      editable: false,

      renderCell: CellExibition,
    },
    {
      field: "fabricante",
      headerName: "FABRICANTE",
      editable: false,
      width: 150,
      renderCell: CellExibition,
    },
    {
      field: "funcao",
      headerName: "FUNÇÃO",
      width: 150,
      editable: false,
      renderCell: CellExibition,
    },
    {
      field: "modelo",
      headerName: "MODELO",
      width: 150,
      hide: false,
      editable: false,
      renderCell: CellExibition,
    },
    {
      field: "coletor",
      headerName: "COLETOR",
      width: 150,
      editable: false,
      renderCell: CellExibition,
    },
    {
      field: "tipoSnmp",
      headerName: "TIPO SNMP",
      width: 70,
      type: "",
      editable: false,
      renderCell: CellExibition,
    },
    {
      field: "portaSnmp",
      headerName: "PORTA SNMP",
      width: 110,
      editable: false,
      renderCell: CellExibition,
    },
    {
      field: "userSsh",
      headerName: "USER SSH",
      width: 100,
      editable: false,
      renderCell: CellExibition,
    },
    {
      field: "portaSsh",
      headerName: "PORTA SSH",
      width: 100,
      editable: false,
      renderCell: CellExibition,
    },
    {
      field: "senhaSsh",
      headerName: "SENHA SSH",
      width: 100,
      editable: false,
      renderCell: CellExibition,
    },
    {
      field: "userTelnet",
      headerName: "USER TELNET",
      width: 100,
      editable: false,
      renderCell: CellExibition,
    },
    {
      field: "portaTelnet",
      headerName: "PORTA TELNET",
      width: 110,
      editable: false,
      renderCell: CellExibition,
    },
    {
      field: "senhaTelnet",
      headerName: "SENHA TELNET",
      width: 110,
      editable: false,
      renderCell: CellExibition,
    },
  ];

  return (
    <>
      <Stack
        sx={{
          height:
            hosts.length > 19
              ? "800px"
              : hosts.length == 0
              ? "250px"
              : hosts.length * 36 + 110 + "px",
        }}
      >
        <DataGrid
          density="compact"
          sx={styles.dataGrid}
          //  getRowHeight={() => "auto"}
          //autoHeight={true}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={hosts}
          columns={columns}
          pageSizeOptions={[25, 50, 100]}
          initialState={{
            ...hosts.initialState,
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          slots={{
            pagination: CustomPagination,
          }}
          slotProps={{
            pagination: {
              updateStateHost,
              rowSelectionModel,
              editMode: false,
              sendZabbix: false,
            },
          }}
          checkboxSelection
          // isRowSelectable={(params) => !editMode ? true : params.row?.newHost == true ? true : false}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableColumnMenu
          disableColumnFilter
          disableSelectionOnClick
          disableRowSelectionOnClick={true}
        />{" "}
      </Stack>
    </>
  );
}
